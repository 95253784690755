<template>
   <div>
      <v-row :justify="$store.state.sidebarIsOpen ? 'center' : 'center'" align="center" class="mt-5">
         <v-col cols="auto">
            <router-link to="/">
               <img v-if="!$store.state.sidebarIsOpen" src="@/assets/logo.svg" width="105" style="max-height: 70px;"
                  class="d-block ma-auto" alt="kader-POS" />
               <img v-else src="@/assets/logo_icon.svg" width="54" style="max-height: 40px;" class="d-block ma-auto mt-2"
                  alt="kader-POS" />
            </router-link>
         </v-col>
         <v-col cols="auto" class="pa-5 pb-0 mt-2">
            <img src="@/assets/img/svg/menu_close.svg" v-if="$store.state.sidebarIsOpen" height="40" alt=""
               @click.stop="toggleSidebar" :class="$vuetify.rtl == true ? '' : 'rotate-180'" />
            <img src="@/assets/img/svg/menu.svg" v-if="!$store.state.sidebarIsOpen" height="40" alt=""
               @click.stop="toggleSidebar" :class="$vuetify.rtl == true ? '' : 'rotate-180'" />
         </v-col>

      </v-row>
      <v-list dense class="mt-3 px-3">
         <!-- List Level 1 link -->
         <!-- Dashboard -->
         <!-- <span class="d-block text-center">
            <v-list-item active-class="activeItem" link to="/" color="primary" class="my-1">
               <v-list-item-icon>
                  <img src="@/assets/icon.png" alt="حلول القيمة"  style="height: 25px; width : 25px" 
                     :class="$store.state.sidebarIsOpen ? '' : 'mx-n1'" />
               </v-list-item-icon>
               <v-list-item-title class="text-start">{{ $t('dashboard') | capitalize }}</v-list-item-title>
            </v-list-item>
         </span> -->

         <span v-for="(list, i) in menuList" :key="i" class="d-block text-center">
            <v-list-item active-class="activeItem" v-if="Object.keys(list.cats).length == 0" link :to="list.url"
               color="primary">
               <v-list-item-icon v-lazy-container="{ selector: 'img' }">
                  <img :data-src="$api.serverUrl + list.image" :alt="list.title"
                     :class="$store.state.sidebarIsOpen ? '' : 'mx-n2'" />
               </v-list-item-icon>
               <v-list-item-title class="text-start">{{ list.title | capitalize }}</v-list-item-title>
            </v-list-item>
            <!-- List Level 1 group -->
            <v-list-group :ripple="false" color="primary" class="pa-1" active-class="activeLink"
               v-if="Object.keys(list.cats).length >= 1" :value="$store.state.activeScreen.main_id == list.id">
               <template v-slot:activator>
                  <v-list-item-icon v-lazy-container="{ selector: 'img' }">
                     <img :data-src="$api.serverUrl + list.image" :alt="list.title"
                        :class="$store.state.sidebarIsOpen ? '' : 'mx-n2'" />
                  </v-list-item-icon>
                  <v-list-item-title>{{ list.title | capitalize }}</v-list-item-title>
               </template>

               <!-- List Level 2 list-->

               <span v-for="(cats, y) in list.cats" :key="y" class="d-block my-0 text-center">
                  <v-list-item active-class="activeLink" v-if="cats.subs.length == 0" link :to="cats.url">
                     <v-list-item-title>{{ cats.title | capitalize }}</v-list-item-title>
                  </v-list-item>
                  <!-- List Level 2 group-->
                  <div>
                     <v-list-group color="primary" active-class="activeLink" v-if="cats.subs.length >= 1" sub-group
                        :value="$store.state.activeScreen.cat_id == cats.id">
                        <template v-slot:activator>
                           <v-list-item-title>{{ cats.title | capitalize }}</v-list-item-title>
                           <v-icon size="22">mdi-chevron-down</v-icon>
                        </template>

                        <!-- List Level 3 link -->
                        <span v-for="(subs, z) in cats.subs" :key="z" class="d-block my-1">
                           <v-list-item :class="$store.state.activeScreenCode == subs.screen_code ? 'activeLink' : ''"
                              v-if="subs" link :to="subs.url">
                              <v-list-item-title>{{ subs.sub_title | capitalize }}</v-list-item-title>
                           </v-list-item>
                        </span>

                     </v-list-group>
                  </div>
               </span>
            </v-list-group>
         </span>
         <!-- <v-list-item link :to="`/settings_hr`" class="px-6">
            <img v-if="$store.state.activeScreenCode == ''" src="@/assets/img/svg/screen_icon_active.svg" alt="">
            <img v-else src="@/assets/img/svg/screen_icon.svg" alt="">
            <v-list-item-title class="text-start"
               :class="$store.state.activeScreenCode == '' ? 'blue3--text' : 'primary--text'">
               {{ $t('settings') | capitalize }}</v-list-item-title>
         </v-list-item> -->
      </v-list>
      <!-- User  -->
      <v-bottom-navigation absolute height="70" color="white" horizontal>
         <v-col cols="12" class="pa-0 py-2 position-relative">
            <v-row justify="center" align="center">
               <v-col cols="auto">
                  <v-avatar size="50" class="rounded-circle userAvatar" v-lazy-container="{ selector: 'img' }">
                     <img :data-src="$store.state.endpointURL + $store.state.userData.image" class="rounded-circle d-block"
                        alt="">
                  </v-avatar>
               </v-col>
               <v-col cols="auto" v-if="!$store.state.sidebarIsOpen">
                  <div class="subtitle-1 gray5--text font-weight-bold">{{ $store.state.userData.full_name || '-' |
                     capitalize }}</div>
                  <div class="subtitle-2  gray8--text">{{ $store.state.userData.title | capitalize }}</div>
               </v-col>
            </v-row>
         </v-col>
      </v-bottom-navigation>
      <!-- End User  -->
   </div>
</template>

<script>
export default {
   name: "Sidebar",
   mounted() {
      // this.menuList = {
      //    0: {
      //       cats: [],
      //       id: 0,
      //       image: 0,
      //       title: 'home',
      //       screen_code: '00-000',
      //       url: '/'
      //    }
      // }
      this.menuList = { ...this.menuList, ...this.$store.state.screens };
      // console.log(this.menuList);
      //>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
      // this.menuList = {
      //    0: {
      //       cats: [
      //          {
      //             "id": 1,
      //             "title": "Header",
      //             "subs": [],
      //             "screen_code": "01-001",
      //             "permission": [
      //                1,
      //                2,
      //                3,
      //                4
      //             ],
      //             "url": "/main/header",
      //             "actions": "1,2,3,4",
      //             "cat_id": 1,
      //             "cat_title": "Main Data",
      //             "sub_id": 1,
      //             "sub_title": "Header",
      //             "main_id": 1,
      //             "main_title": 'Header',
      //             "main_image": "/upload/screen_main/107115a0-65e3-41eb-9000-a3d186eb9435.jpeg"
      //          },
      //          {
      //             "id": 2,
      //             "title": "Footer",
      //             "subs": [],
      //             "screen_code": "01-002",
      //             "permission": [
      //                1,
      //                2,
      //                3,
      //                4
      //             ],
      //             "url": "/main/footer",
      //             "actions": "1,2,3,4",
      //             "cat_id": 1,
      //             "cat_title": "Main Data",
      //             "sub_id": 1,
      //             "sub_title": "Footer",
      //             "main_id": 1,
      //             "main_title": 'Footer',
      //             "main_image": "/upload/screen_main/107115a0-65e3-41eb-9000-a3d186eb9435.jpeg"

      //          },
      //       ],
      //       id: 0,
      //       image: 0,
      //       title: 'Main Data',
      //       // title: 'الصفحة الرئيسية',
      //       screen_code: '00-000',
      //       url: '/'
      //    },
      //    2: {
      //       cats: [
      //          {
      //             "id": 1,
      //             "title": "Home Page",
      //             "subs": [
      //                {
      //                   "screen_code": "01-001",
      //                   "permission": [
      //                      1,
      //                      2,
      //                      3,
      //                      4
      //                   ],
      //                   "url": "/main/slide",
      //                   "actions": "1,2,3,4",
      //                   "cat_id": 1,
      //                   "cat_title": "Home Page",
      //                   "sub_id": 1,
      //                   "sub_title": "Slide",
      //                   "main_id": 1,
      //                   "main_title": 'Home Page',
      //                   "main_image": "/upload/screen_main/107115a0-65e3-41eb-9000-a3d186eb9435.jpeg"
      //                },
      //                {
      //                   "screen_code": "01-002",
      //                   "permission": [
      //                      1,
      //                      2,
      //                      3,
      //                      4
      //                   ],
      //                   "url": "/main/section_2",
      //                   "actions": "1,2,3,4",
      //                   "cat_id": 1,
      //                   "cat_title": "Home Page",
      //                   "sub_id": 1,
      //                   "sub_title": "Cases",
      //                   "main_id": 1,
      //                   "main_title": 'Home Page',
      //                   "main_image": "/upload/screen_main/107115a0-65e3-41eb-9000-a3d186eb9435.jpeg"
      //                },
      //                {
      //                   "screen_code": "01-002",
      //                   "permission": [
      //                      1,
      //                      2,
      //                      3,
      //                      4
      //                   ],
      //                   "url": "/main/section_3",
      //                   "actions": "1,2,3,4",
      //                   "cat_id": 1,
      //                   "cat_title": "Home Page",
      //                   "sub_id": 1,
      //                   "sub_title": "Services",
      //                   "main_id": 1,
      //                   "main_title": 'Home Page',
      //                   "main_image": "/upload/screen_main/107115a0-65e3-41eb-9000-a3d186eb9435.jpeg"
      //                },
      //                {
      //                   "screen_code": "01-002",
      //                   "permission": [
      //                      1,
      //                      2,
      //                      3,
      //                      4
      //                   ],
      //                   "url": "/main/section_4",
      //                   "actions": "1,2,3,4",
      //                   "cat_id": 1,
      //                   "cat_title": "Home Page",
      //                   "sub_id": 1,
      //                   "sub_title": "About Us",
      //                   "main_id": 1,
      //                   "main_title": 'Home Page',
      //                   "main_image": "/upload/screen_main/107115a0-65e3-41eb-9000-a3d186eb9435.jpeg"
      //                },
      //                {
      //                   "screen_code": "01-002",
      //                   "permission": [
      //                      1,
      //                      2,
      //                      3,
      //                      4
      //                   ],
      //                   "url": "/main/section_5",
      //                   "actions": "1,2,3,4",
      //                   "cat_id": 1,
      //                   "cat_title": "Home Page",
      //                   "sub_id": 1,
      //                   "sub_title": "Contact Us",
      //                   "main_id": 1,
      //                   "main_title": 'Home Page',
      //                   "main_image": "/upload/screen_main/107115a0-65e3-41eb-9000-a3d186eb9435.jpeg"
      //                },
      //                {
      //                   "screen_code": "01-002",
      //                   "permission": [
      //                      1,
      //                      2,
      //                      3,
      //                      4
      //                   ],
      //                   "url": "/main/customer_contacts",
      //                   "actions": "1,2,3,4",
      //                   "cat_id": 1,
      //                   "cat_title": "Home Page",
      //                   "sub_id": 1,
      //                   "sub_title": "Customer Contacts",
      //                   "main_id": 1,
      //                   "main_title": 'Home Page',
      //                   "main_image": "/upload/screen_main/107115a0-65e3-41eb-9000-a3d186eb9435.jpeg"
      //                }
      //             ],
      //             "screen_code": "01-001",
      //             "permission": [
      //                1,
      //                2,
      //                3,
      //                4
      //             ],
      //             "url": "/main/header",
      //             "actions": "1,2,3,4",
      //             "cat_id": 1,
      //             "cat_title": "Body",
      //             "sub_id": 1,
      //             "sub_title": "Home Page",
      //             "main_id": 1,
      //             "main_title": 'Home Page',
      //             "main_image": "/upload/screen_main/107115a0-65e3-41eb-9000-a3d186eb9435.jpeg"
      //          },

      //       ],
      //       id: 0,
      //       image: 0,
      //       title: 'Body',
      //       screen_code: '00-000',
      //       url: '/'
      //    }
      // }

      // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>
   },
   data: () => ({
      menuList: []
   }),
   methods: {
      toggleSidebar() {
         this.$store.state.sidebarIsOpen = !this.$store.state.sidebarIsOpen;
      },
   }
};
</script>

<style scoped lang="scss">
.activeLink {
   opacity: 1 !important;
   font-weight: 500 !important;

   color: #212121;

   img:first-of-type {
      display: none;
   }

   img:last-of-type {
      display: block;
   }
}

span {
   img {
      width: 20px;
      height: 20px;
   }

   // img:last-of-type {
   //    display: none;
   // }
}

.v-list-item,
.v-list-item--link,
.v-list-item--active {
   // border-radius: 10px !important;
}

.theme--light .activeLink {
   background: transparent !important;
   color: #1a50ff;

   .v-list-item__title {
      font-weight: bold !important;
   }
}

.theme--light .screen_sub {
   color: #5896e1;
}

.theme--light.v-application--is-rtl .activeLink {
   background: transparent !important;
   color: #1a50ff;

   .v-list-item__title {
      font-weight: bold !important;
   }
}

.theme--dark .activeLink {
   border-left: 4px solid #66b4e3;
   border-right: 0px solid #66b4e3;
}

.theme--dark.v-application--is-rtl .activeLink {
   border-left: 0 solid #66b4e3;
   border-right: 4px solid #66b4e3;
   color: #66b4e3;
}

.v-list-item img {
   // filter: grayscale(100%) !important;
}

.v-list-item--active img {
   // filter: grayscale(0%) !important;
}

.v-application--is-ltr .v-list-item__icon:first-child {
   margin-right: 12px !important;
}

.v-application--is-ltr .v-navigation-drawer--mini-variant .v-list-item__icon:first-child {
   margin-right: 0 !important;
}

.v-list-group .v-list-item__title {
   text-align: initial;
   font-size: 12px;
}

.v-list-group .v-list-item--link {
   text-align: initial;
   padding: 0 2.5rem;
}

.v-list-item--link {
   //opacity: 0.7;
}

.v-application--is-rtl .v-navigation-drawer--mini-variant .v-list-item__icon:first-child {
   margin-right: 0 !important;
}

.v-application--is-rtl .v-list-item__icon:first-child {
   margin-left: 0;
   margin-right: 0;
}

.v-divider {
   opacity: 0.65 !important;
   width: 200px !important;
}

.theme--light {
   .v-list-group--active {
      background: #e6ecff !important;
      border-radius: 9px !important;
   }
}

.theme--dark {
   .v-list-item--link {
      opacity: 1;
   }

   .v-list-group--active {
      background: #191919 !important;
      border-radius: 9px !important;

      .v-list-item__title {
         color: #fff !important
      }
   }

   .activeItem .v-list-item__title {
      color: #fff !important
   }
}

.theme--dark {
   .activeItem {
      background: #191919 !important;
      color: #191919 !important
   }
}

.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
   opacity: 0;
}

.v-list-group--sub-group .v-list-group__header.v-list-item--active .v-list-item__title {
   font-weight: bold;
   color: #1a50ff !important;
}


.v-list-group--sub-group .v-list-group__header.v-list-item--active .v-icon {
   color: #1a50ff !important;
   transform: rotate(180deg);
}

.activeItem {
   background: #e6ecff !important;
   border-radius: 9px !important;

   .v-list-item__title {
      font-weight: bold !important;
      color: #334D6E !important;
   }
}

.v-list-item__title {
   font-weight: bold !important;
}

.userAvatar {
   img {
      padding: 3px;
   }
}
</style>