<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid" :deleteMethod="deleteMethod" />
         <!-- End Of Header -->

         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3">
            <v-card class="backgroundW shadow pa-5 pt-7">
               <v-row align="center">


                  <GenericInput type="text" :value="form.title_ar" @input="form.title_ar = $event" label="عنوان عربى"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" class="mt-1" />

                  <GenericInput type="text" :value="form.title_en" @input="form.title_en = $event" label="English title"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" class="mt-1" />


                  <GenericInput type="checkbox" :value="form.master" @input="form.master = $event" label="fixed"
                     :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" class="mt-1" />


                  <GenericInput type="float" :value="form.price" @input="form.price = $event" label="consultation price"
                     :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" class="mt-1" />


                  <GenericInput type="texteditor" :value="form.description_ar" @input="form.description_ar = $event"
                     label="الوصف عربى" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]"
                     class="mt-2" />

                  <GenericInput type="texteditor" :value="form.description_en" @input="form.description_en = $event"
                     label="Details english" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]"
                     class="mt-2" />


                  <GenericInput type="file" :value="form.image" @input="form.image = $event" label="image" :required="true"
                     :base64="false" :isLoading="pageData.editIsLoading" prependIcon="mdi-camera" :cols="[12, 12, 12]"
                     class="mt-2" />

               </v-row>
            </v-card>

         </v-form>

         <!-- End of Form -->
      </v-container>
   </section>
</template>

<style scoped lang="scss">
#userSelections {
   .v-chip.v-size--small {
      display: none !important;
   }
}
</style>

<script>

export default {
   name: "ConsultationControl",

   data: () => ({
      pageData: {
         screen_code: "03-001",
         url: null,
         controlRoute: "/consulting/consultation-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: false,
         isEdit: false,
         editIsLoading: false,
      },
      valid: false,
      form: {
         title_ar: null,
         title_en: null,
         description_ar: null,
         description_en: null,
         image: null,
         price: 0,
         master: 0,
      },
      tab: null,

   }),
   components: {

   },
   computed: {

   },
   mounted() {
      this.pageMainData()
      this.getData()
   },
   watch: {

   },
   methods: {

      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$route.params.id ? this.$i18n.t("edit") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title : this.$i18n.t("add") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title;
      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`consultation/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     Object.keys(this.form).forEach(key => {
                        this.form[key] = response.data.data[key]
                     });
                     this.$refs.form.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         } else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {

               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`consultation/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.clients = response.data.clients;
                  }
               })
            } else {
               this.$router.push('/')
            }

         }

      },
      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               let requestBody = { ...this.form, _method: 'PUT' };
               console.log();
               requestBody.image = typeof this.form?.image == 'string' || !this.form?.image  ? null : this.form?.image;
               this.$api.POST_METHOD_MULTIPART(`consultation/${this.$route.params.id}`, requestBody).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check == true) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
            else {
               this.$api.POST_METHOD_MULTIPART(`consultation`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check == true) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD_MULTIPART(`consultation/${this.$route.params.id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url)
               this.pageData.isLoading = false
            })
         }
      },
   },
};
</script>
