<template>
    <v-dialog v-model="openDialog" @click:outside="close" :retain-focus="false" max-width="900">
        <v-form ref="form" v-model="valid">
            <v-card class="shadow pa-5 card-style" :key="id">
                <section>
                    <v-row align="center">
                        <v-col cols="12">
                            <div class="d-flex justify-space-between px-5">
                                <h5> {{ $t('Modify a reservation') | capitalize }} - {{ cardData.user_full_name }}</h5>
                                <v-btn class="error--text" icon depressed @click="close">
                                    <v-icon size="24" color="error"> mdi-close</v-icon>
                                </v-btn>
                            </div>
                        </v-col>

                        <v-col cols="12" class="scrollBar">
                            <v-card class="main-card-style shadow-none pa-5 mb-8" elevation="0">
                                <v-row>
                                    <v-col cols="12" lg="1">
                                        <v-img lazy-src="@/assets/img/png/has_scheduled_icon.png" max-width="100"
                                            :src="$global.endpointURL() + cardData.user_image"></v-img>
                                    </v-col>


                                    <v-col cols="12" lg="11" class="px-8 d-flex ">
                                        <GenericInput type="date" :value="form.day" @input="form.day = $event" label="date"
                                            :required="true" :isLoading="false" :cols="[12, 6, 6]" />

                                        <GenericInput v-if="form.day" type="select" :lookups="times" :value="form.times"
                                            @input="form.times = $event" label="time" selected_label="time"
                                            selected_prop="time" :required="true" :isLoading="isLoading"
                                            :cols="[12, 6, 6]" />
                                    </v-col>

                                    <!-- Actions button -->
                                    <v-col cols="12" class="d-flex justify-end">
                                        <v-btn depressed text :disabled="false" @click="close" height="40" width="200"
                                            class="rounded-lg font-weight-bold  white--text mx-3" color="error"
                                            :loading="saveDataLoading">
                                            {{ $t('back') }}
                                        </v-btn>

                                        <v-btn depressed @click="save" height="40" width="200"
                                            class="rounded-lg font-weight-bold " color="success" :loading="saveDataLoading">
                                            {{ $t('save') }}
                                        </v-btn>

                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                       
                    </v-row>
                </section>
            </v-card>

        </v-form>

    </v-dialog>
</template>
<script>

export default {

    name: "HasScheduledEditDialog",

    data() {
        return {
            valid: null,
            id: null,
            isLoading: false,
            saveDataLoading: false,
            dialogTitle: '',
            times: [],
            form: {
                day: null,
                times: null
            }

        };
    },


    mounted() {

    },

    computed: {
        disabled() {
            return (
                Object.keys(this.form).some(key => !this.form[key])
            );
        },

        filteredTimeId() {
            const matchingTimeSlot = this.times.find(timeSlot => timeSlot.time === this.form.times);
            return matchingTimeSlot || {};
        },
    },

    methods: {

        getData() {
            this.id += 1
            this.isLoading = true
            this.times = []
            this.$api.GET_METHOD(`available_time?day=${this.form.day}`).then((response) => {
                this.isLoading = false
                if (response.check) {
                    this.dayAvailableTimes = response.data.items
                    const specificDate = this.form.day;
                    if (this.dayAvailableTimes[specificDate]) {
                        this.dayAvailableTimes[specificDate].forEach(time => {
                            if (time.reserved == 0 && !this.times.includes(time)) {
                                this.times.push(time);
                            }
                        });
                    }

                }
            });

        },

        save() {
            this.saveDataLoading = true;
            const form = {
                time_id: this.filteredTimeId.id,
                _method: 'PUT'
            }
            this.$api.POST_METHOD(`booking/${this.cardData.id}`, form).then((response) => {
                this.saveDataLoading = false;
                if (response.check) {
                    this.updateBackGround()
                    this.updateMainBackground()
                    this.close()
                }
            })
        },

        close() {
            // this.dialogTitle = '',
            Object.keys(this.form).forEach(key => this.form[key] = null);
            this.id = 0
            this.closeDialog()
        },

    },

    watch: {

        'form.day'() {
            if (this.form.day) {
                this.getData()
                console.log('form.day:');
            }

        },

        openDialog() {
            if (this.openDialog) {
                this.form.day = this.cardData.date
                this.form.times = this.cardData.time
                this.id += 1
                // this.getData()
            }
        },

    },


    props: {
        openDialog: { default: false },
        closeDialog: { type: Function },
        updateBackGround: { type: Function },
        updateMainBackground: { type: Function },
        cardData: { default: Object },
        data: { default: Object },
    },

    components: {}
}
</script>

<style lang="scss" scoped>
.scrollBar {
    overflow: auto;
    white-space: nowrap;
    max-height: 69.5vh;
}


.card-style {
    border-radius: var(--3, 1rem);
    background: var(--gray-12, #F9F9F9);
    box-shadow: 0px -9px 16px 0px rgba(0, 0, 0, 0.13) inset;
    backdrop-filter: blur(100px);
}

.main-card-style {
    border-radius: 1rem !important;
    background: var(--White, #FFF);
}


.main-card-style .underLine {
    color: blue;
    text-decoration: underline;
    font-weight: bold;
}

.scrollBar::-webkit-scrollbar {
    width: 2px;
}

.scrollBar::-webkit-scrollbar-thumb {
    background-color: var(--primary);
}
</style>