// Consultation
import ConsultationEntity from "@/views/ConsultingModule/Consultation/ConsultationEntity";
import ConsultationControl from "@/views/ConsultingModule/Consultation/ConsultationControl";

// Booking
import BookingEntity from "@/views/ConsultingModule/Booking/BookingEntity";

// Blog
import BlogEntity from "@/views/ConsultingModule/blog/BlogEntity";
import BlogControl from "@/views/ConsultingModule/blog/BlogControl";




// Routes
export const ConsultingModuleChildrens = [

  // Consultation -------------------------------------- 
  {
    path: "/consulting/consultation",
    name: "ConsultatioEntityVue",
    component: ConsultationEntity,
    meta: {
      screen_code: "03-001",
    },
  },
  {
    path: "/consulting/consultation-control",
    name: "ConsultationControlVue",
    component: ConsultationControl,
    meta: {
      screen_code: "03-001",
    },
  },
  {
    path: "/consulting/consultation-control/:id",
    name: "ConsultationControlVueEdit",
    component: ConsultationControl,
    meta: {
      screen_code: "03-001",
    },
  },

  // Booking -------------------------------------- 
  {
    path: "/consulting/booking",
    name: "BookingEntityVue",
    component: BookingEntity,
    meta: {
      screen_code: "03-002",
    },
  },

  // Blog------------------------------------------------
  {
    path: "/consulting/blog",
    name: "BlogEntity",
    component: BlogEntity,
    meta: {
      screen_code: "03-003",
    },
  },
  {
    path: "/consulting/blog-control",
    name: "BlogControlVue",
    component: BlogControl,
    meta: {
      screen_code: "03-003",
    },
  },
  {
    path: "/consulting/blog-control/:id",
    name: "BlogControlEdit",
    component: BlogControl,
    meta: {
      screen_code: "03-003",
    },
  },

]