<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid && validateServicesImgs" :showClose="false" />
         <!-- End Of Header -->
         <v-form ref="form" v-model="valid" class="mt-3">
            <v-row>
               <v-col cols="12">
                  <v-card class="shadow py-5 rounded-lg">
                     <v-col cols="12" class="text-capitalize">{{ $t('Section title') }}</v-col>
                     <v-row class="align-center px-4 py-2 text-capitalize">
                        <AnimatedLoading class="mx-auto" :isLoading="pageData.isLoading" />

                        <GenericInput type="text" v-if="!pageData.isLoading" label="name_ar" @input="form.department_address_ar = $event"
                           :value="form.department_address_ar" :required="true" :isLoading="pageData.editIsLoading"
                           :cols="[12, 6, 6]">
                        </GenericInput>
                        <GenericInput type="text" v-if="!pageData.isLoading" label="name_en" @input="form.department_address_en = $event"
                           :value="form.department_address_en" :required="true" :isLoading="pageData.editIsLoading"
                           :cols="[12, 6, 6]">
                        </GenericInput>
                     </v-row>
                  </v-card>
               </v-col>

               <v-col cols="12">
                  <v-card class="shadow py-5 rounded-lg services_card">
                     <v-row class="align-center px-4 py-2 text-capitalize" :key="counter">
                        <v-col cols="12" class="text-capitalize">{{ $t('services') }}</v-col>

                        <!-- <AnimatedLoading :isLoading="pageData.isLoading" class="mx-auto mt-16" />
                        <v-col cols="12" class="d-flex align-center justify-start" v-for="(service, i) in form.services"
                           :key="service.id">
                           <div class="d-flex flex-column align-start justify-center" v-if="!pageData.isLoading">
                              <div class="mx-auto my-3"> {{ $t('Service No') }} {{ i + 1 }}</div>

                              <div class="mx-10">
                                 <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                       <div v-bind="attrs" v-on="on">
                                          <AvatarProfile :img="form.services[i].image"
                                             :callBackMethod="(img) => callBackImg(i, img)" :showDelete="false"
                                             :showText="false" :requestDeleteImage="requestDeleteImage" />
                                       </div>
                                    </template>
                                    <span>{{ '100' + 'x' + '100' }}</span>
                                 </v-tooltip>
                              </div>
                           </div>

                           <div class="d-flex align-center flex-wrap flex-grow-1" v-if="!pageData.isLoading">
                              <GenericInput type="text" @input="form.services[i].address = $event"
                                 :value="form.services[i].address" :required="form.services[i].active == 1"
                                 label="Service Address" :isLoading="pageData.editIsLoading" :cols="[10, 10, 10]">
                              </GenericInput>
                              <GenericInput type="text" @input="form.services[i].description = $event"
                                 :value="form.services[i].description" :required="form.services[i].active == 1"
                                 label="Service Description" :isLoading="pageData.editIsLoading" :cols="[10, 10, 10]">
                              </GenericInput>
                              <GenericInput type="switch" class="mb-10" @input="form.services[i].active = $event"
                                 :value="form.services[i].active" label="status" :required="false"
                                 :isLoading="pageData.editIsLoading" :cols="['auto', 'auto', 'auto']">
                              </GenericInput>
                           </div>

                        </v-col> -->


                        
                        <v-col cols="12" sm="12">
                           <DynamicTable :isLoading="pageData.isLoading" :data="form.services" :header="servicesTableHeader"
                              :option="pageData.options" :pageData="pageData" :printValueMethod="printMethod">

                              <template v-slot:td="{ row, header, index }">
                                 <div v-if="header.key == 'image'" class="flex-grow-1">
                                    <div>
                                       <v-tooltip bottom>
                                          <template v-slot:activator="{ on, attrs }">
                                             <div v-bind="attrs" v-on="on">
                                                <AvatarProfile :img="form.services[index].image"
                                                   :callBackMethod="(img) => callBackImg(index, img)" :showDelete="false"
                                                   :showText="false" :requestDeleteImage="requestDeleteImage" />
                                             </div>
                                          </template>
                                          <span>{{ '100' + 'x' + '100' }}</span>
                                       </v-tooltip>
                                    </div>

                                 </div>
                                 <div v-if="header.key == 'name_ar'" class="flex-grow-1">
                                    <GenericInput type="text" @input="row.address_ar = $event" :value="row.address_ar"
                                       :required="row.active == 1" :isLoading="pageData.editIsLoading"
                                       :cols="[12, 12, 12]">
                                    </GenericInput>
                                    <GenericInput type="text" @input="row.description_ar = $event" :value="row.description_ar"
                                       :required="row.active == 1" :isLoading="pageData.editIsLoading"
                                       :cols="[12, 12, 12]">
                                    </GenericInput>
                                 </div>
                                 <div v-if="header.key == 'name_en'" class="flex-grow-1">
                                    <GenericInput type="text" @input="row.address_en = $event" :value="row.address_en"
                                       :required="row.active == 1" :isLoading="pageData.editIsLoading"
                                       :cols="[12, 12, 12]">
                                    </GenericInput>
                                    <GenericInput type="text" @input="row.description_en = $event" :value="row.description_en"
                                       :required="row.active == 1" :isLoading="pageData.editIsLoading"
                                       :cols="[12, 12, 12]">
                                    </GenericInput>
                                 </div>
                                 <div v-if="header.key == 'active'" >
                                    <GenericInput type="switch" :value="row.active" @input="row.active = $event"
                                       :required="false" :isLoading="pageData.editIsLoading" :cols="[3, 3, 3]">
                                    </GenericInput>
                                 </div>
                              </template>
                           </DynamicTable>
                        </v-col>
                     </v-row>
                  </v-card>
               </v-col>
            </v-row>
         </v-form>

      </v-container>
   </section>
</template>



<script>
import AvatarProfile from '@/components/ui/AvatarProfile.vue';

export default {
   name: "ServicesEntity",

   data: () => ({
      pageData: {
         screen_code: "02-004",
         url: null,
         controlRoute: "website/services-control",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isTrashed: 1,
         rowsNumber: 15,
         tableHeader: [],
         search: '',
         rows: [],
         options: {},
         page: 1,
         pagination: {},
         rearrangementDialog: false,
      },
      valid: false,
      form: {
         department_address_ar: null,
         department_address_en: null,
         services: [],
      },
      servicesTableHeader:[],
      counter: 0,
   }),
   components: {
      AvatarProfile,
   },
   computed: {
      validateServicesImgs() {
         return this.form.services.filter(service => service.active == 1).every((service) => service.image !== null)
      }
   },
   watch: {
      $route() {
         this.queryParams();
         this.pageMainData();
         this.getData();
      },
   },
   mounted() {
      this.pageMainData();
      this.getData();
   },
   methods: {
      callBackImg(i, img) {
         this.form.services[i].image = img
         console.log('i,img', i, img);
         if (
            this.$global.FILE_TYPE(img) !== 'png' &&
            this.$global.FILE_TYPE(img) !== 'jpg' &&
            this.$global.FILE_TYPE(img) !== 'svg' &&
            this.$global.FILE_TYPE(img) !== 'jpeg'
         ) {
            this.$store.state.snackbarTitle = this.$i18n.t("Please upload photos only")
            this.$store.state.snackbarType = "info";
            this.$store.state.showSnackbar = true;
            this.requestDeleteImage = true;
            this.form.services[i].image = '';
            img = null;
            this.counter++
         }
      },
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code)?.main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code)?.cat_title;
         this.pageData.entityName = this.$store.state.activeScreen?.sub_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code)?.url;
         this.pageData.options = {
            print: this.$global.CheckAction(this.pageData.screen_code, 5),
            delete: this.$global.CheckAction(this.pageData.screen_code, 4),
            edit: this.$global.CheckAction(this.pageData.screen_code, 3),
            restore: this.$global.CheckAction(this.pageData.screen_code, 4),
            view: this.$global.CheckAction(this.pageData.screen_code, 1),
            switch: false,
            selectRow: false,
            sortBy: 'id',
            tableHeader: false,
            searchInput: false,
            printButton: false,
            handleClickRow: false,
         }
         this.servicesTableHeader = [
            // { text: "serial", key: "id", type: 'text', classes: "" },
            { text: "image", key: "image", type: 'slot', classes: "" },
            { text: "Text in Arabic", key: "name_ar", type: 'slot', classes: "" },
            { text: "Text in English", key: "name_en", type: 'slot', classes: "" },
            { text: "status", key: "active", type: 'slot', classes: "" },
         ]
      },
      getData() {
         this.pageData.isLoading = true;
         this.$api.GET_METHOD(`service`).then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
               this.form.department_address_en = response.data.department_address.department_address_en;
               this.form.department_address_ar = response.data.department_address.department_address_ar;
               this.form.services = response.data.items;
            }
         })
      },
      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            // this.form._method = 'PUT'
            this.$api.POST_METHOD(`service_update`, this.form).then((response) => {
               this.pageData.isLoading = false;
               if (response.check) {
                  console.log('response');
               }
            })
         }
      },
   },
};
</script>

<style lang="scss" scoped>
.services_card {
   height: 70vh;
   overflow: auto;
   overflow-x: hidden;
}
</style>

