<template>
  <v-card elevation="0" class="ma-0 pa-0 px-5 transparent rounded-lg">
    <v-row justify="space-between" align="center">
      <v-col cols="auto">
        <v-row align="center">
          <v-col cols="12" sm="auto" class="d-flex justify-center align-center">
            <div class="subtitle-2 gray8--text  font-weight-medium">
              <!-- {{ pageData.main }} >  -->
              {{ pageData.category }} {{ pageData.category && '>' }}
            </div>
            <div class="text-h6 mx-2 mt-n1 gray1--text  text-capitalize">
              <strong v-text="pageData.entityName" />
            </div>
          </v-col>
          <!-- <v-col class="px-0" v-if="showSearch || dateRange">
            <v-divider vertical style="height: 25px" class="d-block ma-auto"></v-divider>
          </v-col> -->

          <slot name="leftSide"></slot>
        </v-row>
      </v-col>
      <slot name="centerSide"></slot>
      <v-col cols="auto" sm="auto" class="pa-sm-0">
        <v-row justify="center" justify-sm="end" align="center">
          <slot name="rightSide"></slot>
          <v-col cols="12" lg="auto" sm="auto" class="py-0" v-if="showSearch">
            <v-text-field v-model="pageData.search" @keydown.enter="(e) => getSearch(e.target.value)"
              @click:clear="(e) => getSearch(e.target.value)" class="rounded-pill" background-color="#eceef0"
              @click:append="getSearch(pageData.search)" append-icon="mdi-magnify" :placeholder="
                pageData.isTrashed == 0 ? $i18n.t('Search in deleted') : $i18n.t('Search')
              " single-line clearable hide-details solo dense :height="37"></v-text-field>
          </v-col>
          <GenericInput type="date" :solo="true" classes="rounded-lg" v-if="dateRange" :value="pageData.from"
            @input="pageData.from = $event" label="from" :required="false" :hide-details="true"
            :isLoading="pageData.editIsLoading" :cols="[12, 'auto', 'auto']">
          </GenericInput>
          <GenericInput type="date" :solo="true" classes="rounded-lg" v-if="dateRange" :value="pageData.to"
            @input="pageData.to = $event" label="to" :required="false" :hide-details="true"
            :isLoading="pageData.editIsLoading" :cols="[12, 'auto', 'auto']"></GenericInput>
          <v-col cols="auto" class="pa-0" v-if="showDeleted">
            <v-btn outlined class="rounded-lg mx-2" style="background-color:  #e6ecff ;" v-if="pageData.isTrashed == 0"
              @click="changeStatus(1)" depressed color="blue2" :height="37">
              <img src="@/assets/img/svg/pin.svg" height="16" class="mx-1" alt="" />
              {{ $t('pin') }}
            </v-btn>
            <v-btn depressed outlined class="rounded-lg mx-2" color="error" style="background-color:  #feeaea ;"
              v-if="pageData.isTrashed == 1" @click="changeStatus(0)" :height="37">
              <img src="@/assets/img/svg/delete.svg" height="16" class="mx-1" alt="" />
              {{ $t('deleted') }}
            </v-btn>
          </v-col>
          <v-col cols="12" sm="auto" v-if="actionBtn == true">
            <v-btn depressed @click="actionBtnClick" :outlined="actionBtnOutline" :loading="pageData.isLoading"
              :disabled="!actionBtnValid" class="my-2 rounded-lg" :class="actionBtnClass" :color="actionBtnColor"
              :height="37">
              <v-icon size="18" left v-if="actionBtnIcon">{{ actionBtnIcon }}</v-icon>
              <span>{{ $t(actionBtnText) }}</span>
            </v-btn>
          </v-col>
          <v-col cols="12" sm="auto" v-if="createBtn">
            <v-btn depressed :min-width="90" v-if="$global.CheckAction(pageData.screen_code, 1)"
              @click="createBtnClicked" class="my-2 rounded-lg" color="primary" :height="37">
              <v-icon left>mdi-plus</v-icon>
              {{ $t(createBtnText) }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="pa-0">
        <v-divider></v-divider>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import GenericInput from "./GenericInput.vue";
const { mapState, mapActions } = createNamespacedHelpers("Settings");
export default {
  name: "EntityHeader",
  computed: {
    ...mapState(["favourites"]),
  },
  props: {
    pageData: { default: {} },
    getData: { type: Function },
    getSearch: { type: Function },
    createBtn: { default: true },
    createBtnText: { default: "create" },
    sortButton: { default: false },
    actionBtn: { default: false },
    actionBtnColor: { default: "accent" },
    actionBtnValid: { default: false },
    actionBtnClick: { type: Function },
    actionBtnText: { default: "action btn" },
    actionBtnClass: { default: "white--text" },
    actionBtnIcon: { default: null },
    showSetting: { default: false },
    showFilter: { default: false },
    showSearch: { default: true },
    showDeleted: { default: true },
    dateRange: { default: false },
    actionBtnOutline: { default: false },
  },
  methods: {
    ...mapActions(["addFavourites"]),
    addToFav() {
      const fav = {
        screen_code: this.pageData.screen_code,
        name: this.pageData.entityName,
        url: this.pageData.url,
      };
      this.addFavourites(fav).finally(() => { });
    },
    createBtnClicked() {
      if (this.pageData.controlRoute) {
        this.$router.push('/' + this.pageData.controlRoute)
      }
      else {
        this.pageData.controlID = null;
        this.pageData.controlDialog = true;
      }
    },
    changeStatus(status) {
      if (this.pageData.queryParam) {
        this.$router.push(this.pageData.url + `?active=${status}&rows=10&page=1&word=`);
      }
      else {
        this.pageData.isTrashed = status
        this.getData();
      }
    }
  },
  components: { GenericInput }
};
</script>

<style scoped lang="scss">

</style>
