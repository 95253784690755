<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <EntityHeader :pageData="pageData" :getData="getData" :getSearch="getSearch" actionBtnText="Rearrangement"
            :actionBtnClick="openSort" :actionBtn="true" :actionBtnValid="true" actionBtnColor="#2D3E50"
            actionBtnClass="white--text" actionBtnIcon="mdi-sort-numeric-ascending" />
         <!-- End Of Header -->

         <v-row>
            <v-col cols="12" sm="12">
               <DynamicTable :isLoading="pageData.isLoading" deleteProperty="id" :data="pageData.rows"
                  :header="pageData.tableHeader" :option="pageData.options" :editValueMethod="edit" :pageData="pageData"
                  :deleteValueMethod="deleteMethod" :restoreValueMethod="restoreMethod" />
            </v-col>
         </v-row>
         <v-row v-if="!pageData.isLoading">
            <v-col cols="12" sm="12" class="py-0">
               <Pagination :pagination="pageData.pagination" :limit="pageData.rowsNumber" :backValueMethod="changePage"
                  :isLoading="pageData.isLoading"></Pagination>
            </v-col>
         </v-row>

         <CategoriesMenuRearrangementDialog v-if="pageData.rearrangementDialog == true"
            :dialog="pageData.rearrangementDialog" :backValueMethod="rearrangement" :isLoading="pageData.isLoading" />
      </v-container>
   </section>
</template>



<script>
import CategoriesMenuRearrangementDialog from "./CategoriesMenuRearrangementDialog.vue";
import EntityHeader from "@/components/ui/EntityHeader.vue";
export default {
   name: "CategoriesMenuEntity",

   data: () => ({
      pageData: {
         screen_code: "01-002",
         url: null,
         controlRoute: "main/screen_cat-control",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isTrashed: 1,
         rowsNumber: 15,
         tableHeader: [],
         search: '',
         rows: [],
         options: {},
         page: 1,
         pagination: {},
         rearrangementDialog: false,
      },
   }),
   components: {
      CategoriesMenuRearrangementDialog,
      EntityHeader
   },
   computed: {
   },
   mounted() {
      this.pageMainData();
      this.getData();
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code)?.main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code)?.cat_title;
         this.pageData.entityName = this.$store.state.activeScreen?.sub_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code)?.url;

         this.pageData.tableHeader = [
            { text: "#", key: "id", type: 'text', classes: "" },
            { text: "main image", key: "main_image", type: 'img', classes: "" },
            { text: "main title", key: "main_title", type: 'text', classes: "" },
            { text: "title", key: "title", type: 'text', classes: "" },
            { text: "actions", key: "id", type: 'actions', classes: "" },]
         this.pageData.options = {
            print: this.$global.CheckAction(this.pageData.screen_code, 5),
            delete: this.$global.CheckAction(this.pageData.screen_code, 4),
            edit: this.$global.CheckAction(this.pageData.screen_code, 3),
            restore: this.$global.CheckAction(this.pageData.screen_code, 4),
            view: this.$global.CheckAction(this.pageData.screen_code, 1),
         }
      },
      getData() {
         if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
            this.pageData.isLoading = true;
            this.$api.GET_METHOD(`screen_cat?active=${this.pageData.isTrashed}&rows=${this.pageData.rowsNumber}&page=${this.pageData.page}&word=${this.pageData.search || ''}`).then((response) => {
               this.pageData.isLoading = false;
               if (response.check) {
                  this.pageData.entityName = this.pageData.isTrashed == 0 ? this.$i18n.t("deleted from") + " " + this.$store.state.activeScreen.sub_title : this.$store.state.activeScreen.sub_title;
                  this.pageData.rows = response.data.items.data;
                  this.pageData.page = response.data.items.current_page;
                  this.pageData.pagination = {
                     page: response.data.items.current_page,
                     totalPages: response.data.items.last_page,
                     per_page: response.data.items.per_page,
                     totalRows: response.data.items.total,
                  }

               }
            })

         }
         else {
            this.$router.push('/')
         }

      },
      openSort() {
         this.pageData.rearrangementDialog = true
      },
      getSearch(word) {
         this.pageData.search = word ? word : "";
         this.getData();
      },
      changePage(page, limit) {
         this.pageData.page = page;
         this.pageData.rowsNumber = limit;
         this.getData()
      },
      deleteMethod(id) {
         this.$api.POST_METHOD(`screen_cat/${id}`, { _method: 'delete' }).then((response) => {
            if (response.check) {
               this.getData()
            }
         })
      },
      restoreMethod(row) {
         this.$api.POST_METHOD(`screen_cat_toggle_active/${row.id}`, null).then((response) => {
            if (response.check) {
               this.getData()
            }
         })
      },
      setClickRow() {
         // this.$router.push(`/main/network_machine/${row.id}`)
      },
      edit(row) {
         this.$router.push(`/${this.pageData.controlRoute}/` + row.id)
      },
      rearrangement(newDataOrder, status) {
         if (status == true) {
            this.$api.POST_METHOD(`screen_cat_sort`, { items: newDataOrder }).then((response) => {
               if (response.check) {
                  this.getData()
                  this.pageData.rearrangementDialog = false
               }
            })
         } else {
            this.pageData.rearrangementDialog = false
         }

      }
   },
};
</script>
