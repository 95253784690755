<template>
   <section class="footer_section">
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid && validateSocialImgs && form.logo" :showClose="false"
            :deleteMethod="deleteMethod" />
         <!-- End Of Header -->
         <v-form ref="form" v-model="valid" class="mt-3">

            <!-- logo && language icon -->
            <v-card class="shadow py-5 rounded-lg mt-6">
               <div class="text-capitalize px-8 mb-n9">{{ $t('Logo') }}</div>
               <AnimatedLoading :isLoading="pageData.isLoading" />
               <v-row class="align-center px-4 py-2 text-capitalize" v-if="!pageData.isLoading" :key="counter + 1">
                  <v-col cols="12" md="6">
                     <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                           <div v-bind="attrs" v-on="on">
                              <AvatarProfile :img="form.logo.logo_image"
                                 :callBackMethod="(img) => callBackImg('logo_image', img)" :showDelete="false"
                                 :showText="false" :width="281" :height="200" :requestDeleteImage="requestDeleteImage" />
                           </div>
                        </template>
                        <span>{{ '100' + 'x' + '100' }}</span>
                     </v-tooltip>
                  </v-col>
                  <v-col cols="12" md="6">

                     <GenericInput type="text" @input="form.logo.text_ar = $event" :value="form.logo.text_ar"
                        label="Logo text in Arabic" :required="true" :isLoading="pageData.editIsLoading"
                        :cols="[12, 12, 12]">
                     </GenericInput>

                     <GenericInput type="text" @input="form.logo.text_en = $event" :value="form.logo.text_en"
                        label="Logo text in English" :required="true" :isLoading="pageData.editIsLoading"
                        :cols="[12, 12, 12]">
                     </GenericInput>

                     <GenericInput type="text" @input="form.logo.explore_ar = $event" :value="form.logo.explore_ar"
                        label="Explore in Arabic" :required="true" :isLoading="pageData.editIsLoading"
                        :cols="[12, 12, 12]">
                     </GenericInput>

                     <GenericInput type="text" @input="form.logo.explore_en = $event" :value="form.logo.explore_en"
                        label="Explore in English" :required="true" :isLoading="pageData.editIsLoading"
                        :cols="[12, 12, 12]">
                     </GenericInput>

                     <GenericInput type="text" @input="form.logo.explore_en = $event" :value="form.logo.explore_en"
                        label="Explore in English" :required="true" :isLoading="pageData.editIsLoading"
                        :cols="[12, 12, 12]">
                     </GenericInput>

                     <GenericInput type="number" @input="form.mobile = $event" :value="form.mobile"
                        label="mobile" :required="true" :isLoading="pageData.editIsLoading"
                        :cols="[12, 12, 12]">
                     </GenericInput>
                  </v-col>
               </v-row>
            </v-card>
            <!-- logo && language icon -->

            <!-- Contact us sections -->
            <v-card class="shadow py-5 rounded-lg mt-6">
               <v-row class="align-center px-4 py-2 text-capitalize">

                  <v-col cols="12" class="text-capitalize">{{ $t("Connect with us") }}</v-col>

                  <AnimatedLoading class="ma-auto" :isLoading="pageData.isLoading" />

                  <v-col cols="12" sm="12" v-if="!pageData.isLoading">
                     <DynamicTable :isLoading="pageData.isLoading" :data="form.contact_us" :header="pageData.tableHeader"
                        :option="pageData.options" :pageData="pageData" :printValueMethod="printMethod">
                        <template v-slot:td="{ row, header }">
                           <div class="flex-grow-1" v-if="header.key == 'name_ar'">
                              <GenericInput type="text" @input="row.name_ar = $event" :value="row.name_ar"
                                 :required="row.active == 1" :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]">
                              </GenericInput>
                           </div>
                           <div class="flex-grow-1" v-if="header.key == 'name_en'">
                              <GenericInput type="text" @input="row.name_en = $event" :value="row.name_en"
                                 :required="row.active == 1" :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]">
                              </GenericInput>
                           </div>
                           <div class="flex-grow-1" v-if="header.key == 'link'">
                              <GenericInput type="text" @input="row.link = $event" :value="row.link"
                                 :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]">
                              </GenericInput>
                           </div>
                           <div v-if="header.key == 'active'">
                              <GenericInput type="switch" :value="row.active" @input="row.active = $event"
                                 :required="false" :isLoading="pageData.editIsLoading" :cols="[3, 3, 3]">
                              </GenericInput>
                           </div>
                        </template>
                     </DynamicTable>
                  </v-col>
               </v-row>
            </v-card>
            <!-- Contact us sections -->

            <!-- social media links arabic-->
            <v-card class="shadow py-5 rounded-lg mt-6">
               <v-row class="align-center px-4  text-capitalize justify-center sideCardCol" :key="counter">

                  <v-col cols="12" class="social_title text-capitalize">{{ $t("Social Media Links") }}</v-col>

                  <AnimatedLoading class="ma-auto" :isLoading="pageData.isLoading" />
                  
                  <v-col cols="12" class="d-flex align-center justify-start" v-for="(social, i) in form.social_media"
                     :key="social.id">
                     <div class="d-flex flex-column align-start justify-center" v-if="!pageData.isLoading">
                        <div class="mx-auto my-3">{{ $t(social.name) }}</div>

                        <div class="mx-10">
                           <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                 <div v-bind="attrs" v-on="on">
                                    <AvatarProfile :img="form.social_media[i].image"
                                       :callBackMethod="(img) => callBackImg(i, img)" :showDelete="false" :showText="false"
                                       :requestDeleteImage="requestDeleteImage"
                                       :classes="(social.active == 1 && social.image === null) && 'alert_border'" />
                                    <!-- :classes="social.active == 1 && social.image ? 'alert_border' : null"  -->
                                 </div>
                              </template>
                              <span>{{ '34' + 'x' + '34' }}</span>
                           </v-tooltip>
                        </div>
                     </div>

                     <div class="d-flex align-center mt-16" v-if="!pageData.isLoading">
                        <GenericInput type="text" :value="social.link" @input="social.link = $event"
                           :required="social.active == 1" :isLoading="pageData.editIsLoading" :cols="[10, 10, 10]">
                        </GenericInput>

                        <GenericInput type="switch" :value="social.active" @input="social.active = $event" label="status"
                           :required="false" :isLoading="pageData.editIsLoading" :cols="['auto', 'auto', 'auto']">
                        </GenericInput>
                     </div>

                  </v-col>
               </v-row>
            </v-card>
            <!-- social media links arabic -->

         </v-form>

      </v-container>
   </section>
</template>



<script>
import AvatarProfile from '@/components/ui/AvatarProfile.vue';

export default {
   name: "FooterEntity",

   data: () => ({
      pageData: {
         screen_code: "02-002",
         url: null,
         controlRoute: "website/footer-control",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isTrashed: 1,
         rowsNumber: 15,
         tableHeader: [],
         search: '',
         rows: [],
         options: {},
         page: 1,
         pagination: {},
         rearrangementDialog: false,
      },
      form: {
         logo: {
            logo_image: null,
            text_ar: null,
            text_en: null,
            explore_ar: null,
            explore_en: null
         },
         mobile: null,
         social_media: [],
         contact_us: []
      },
      valid: false,
      requestDeleteImage: false,
      counter: 0,
   }),
   components: {
      AvatarProfile
   },
   computed: {
      validateSocialImgs() {
         return this.form.social_media.filter(social => social.active == 1).every((social) => social.image !== null)
      }
   },
   $route() {
      this.queryParams();
      this.pageMainData();
      this.getData();
   },
   mounted() {
      this.pageMainData();
      this.getData();
   },
   methods: {
      callBackImg(i, img) {
         if (typeof i === 'string') {
            this.form.logo[i] = img
         } else {
            this.form.social_media[i].image = img
         }
         if (
            this.$global.FILE_TYPE(img) !== 'png' &&
            this.$global.FILE_TYPE(img) !== 'jpg' &&
            this.$global.FILE_TYPE(img) !== 'svg' &&
            this.$global.FILE_TYPE(img) !== 'jpeg'
         ) {
            this.$store.state.snackbarTitle = this.$i18n.t("Please upload photos only")
            this.$store.state.snackbarType = "info";
            this.$store.state.showSnackbar = true;
            this.requestDeleteImage = true;


            if (typeof i === 'string') {
               this.form[i] = img = '';
            } else {
               this.form.social_media[i].image = '';
            }
            img = null;
            this.counter++
         }

      },
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code)?.main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code)?.cat_title;
         this.pageData.entityName = this.$store.state.activeScreen?.sub_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code)?.url;
         this.pageData.tableHeader = [
            // { text: "serial", key: "id", type: 'text', classes: "" },
            { text: "name_ar", key: "name_ar", type: 'slot', classes: "" },
            { text: "name_en", key: "name_en", type: 'slot', classes: "" },
            { text: "link", key: "link", type: 'slot', classes: "" },
            { text: "status", key: "active", type: 'slot', classes: "" },
         ]
         this.pageData.options = {
            print: this.$global.CheckAction(this.pageData.screen_code, 5),
            delete: this.$global.CheckAction(this.pageData.screen_code, 4),
            edit: this.$global.CheckAction(this.pageData.screen_code, 3),
            restore: this.$global.CheckAction(this.pageData.screen_code, 4),
            view: this.$global.CheckAction(this.pageData.screen_code, 1),
            switch: false,
            selectRow: false,
            sortBy: 'id',
            tableHeader: false,
            searchInput: false,
            printButton: false,
            handleClickRow: false,
         }
      },
      getData() {
         this.pageData.isLoading = true;
         this.$api.GET_METHOD(`footer`).then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
               this.form = response.data.items[0];
            }
         })
      },
      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            this.form._method = 'PUT'
            console.log("save",this.form);
            this.$api.POST_METHOD(`footer/1`, this.form).then((response) => {
               this.pageData.isLoading = false;
               if (response.check) {
                  console.log('response');
               }
            })
         }
      },
   },
};
</script>
<style lang="scss" >
.footer_section {
   .sideCardCol {
      height: 51vh;
      overflow: auto;
   }

   .social_title {
      position: sticky;
      top: 0;
      z-index: 5;
      background: white;
   }

   .alert_border {
      div img {
         border: 2px solid red !important;
         // width: 100%;
         // height: 100%;
         // border-radius: 50%;
         // width: 112px !important;
         // height: 112px !important;
      }
   }
}
</style>
