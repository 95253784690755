<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid" :deleteMethod="deleteMethod" :showClose="false" />
         <!-- End Of Header -->
         <v-form ref="form" v-model="valid" class="mt-3">

            <v-row>
               <v-col cols="12" md="12">
                  <v-card class="shadow py-5 rounded-lg card_analysis" :key="counter">
                     <v-row class="align-center px-4 py-2 text-capitalize">
                        <v-col cols="12" class="text-capitalize">{{ $t("Analytics") }}</v-col>

                        <!-- <AnimatedLoading class="mx-auto" :isLoading="pageData.isLoading" />
                        <v-col cols="12" class="d-flex  justify-start" v-for="(analytics, i) in form.cases" :key="i">
                           <div class="d-flex flex-column align-start justify-center" v-if="!pageData.isLoading">
                              <div class="mx-auto my-3">{{ $t('number analysis') }} {{ i + 1 }}</div>

                              <div class="mx-10">
                                 <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                       <div v-bind="attrs" v-on="on">
                                          <AvatarProfile :img="form.cases[i].case_image"
                                             :callBackMethod="(img) => callBackImg(i, img)" :showDelete="false"
                                             :showText="false" :requestDeleteImage="requestDeleteImage" />
                                       </div>
                                    </template>
                                    <span>{{ '100' + 'x' + '100' }}</span>
                                 </v-tooltip>
                              </div>
                           </div>

                           <div class="d-flex align-center flex-wrap flex-grow-1" v-if="!pageData.isLoading">
                              <GenericInput type="text" @input="analytics.content_ar = $event"
                                 :value="analytics.content_ar" :required="true" label="the content in arabic"
                                 :isLoading="pageData.editIsLoading" :cols="[12, 5, 5]">
                              </GenericInput>

                              <GenericInput type="text" @input="analytics.content_en = $event"
                                 :value="analytics.content_en" :required="true" label="the content in english"
                                 :isLoading="pageData.editIsLoading" :cols="[12, 5, 5]">
                              </GenericInput>

                              <GenericInput type="number" @input="analytics.number = $event" :value="analytics.number"
                                 :required="true" label="the number" :isLoading="pageData.editIsLoading"
                                 :cols="[12, 2, 2]">
                              </GenericInput>
                           </div>
                        </v-col> -->

                        <v-col cols="12" sm="12">
                           <DynamicTable :isLoading="pageData.isLoading" :data="form.cases" :header="casesTable"
                              :option="pageData.options" :pageData="pageData" :printValueMethod="printMethod">

                              <template v-slot:td="{ row, header, index }">
                                 <div v-if="header.key == 'image'">
                                    <div>
                                       <v-tooltip bottom>
                                          <template v-slot:activator="{ on, attrs }">
                                             <div v-bind="attrs" v-on="on">
                                                <AvatarProfile :img="form.cases[index].case_image"
                                                   :callBackMethod="(img) => callBackImg(index, img)" :showDelete="false"
                                                   :showText="false" :requestDeleteImage="requestDeleteImage" />
                                             </div>
                                          </template>
                                          <span>{{ '100' + 'x' + '100' }}</span>
                                       </v-tooltip>
                                    </div>

                                 </div>
                                 <div class="flex-grow-1" v-if="header.key == 'name_ar'">
                                    <GenericInput type="text" @input="row.content_ar = $event" :value="row.content_ar"
                                       :required="row.active == 1" :isLoading="pageData.editIsLoading"
                                       :cols="[12, 12, 12]">
                                    </GenericInput>
                                 </div>
                                 <div class="flex-grow-1" v-if="header.key == 'name_en'">
                                    <GenericInput type="text" @input="row.content_en = $event" :value="row.content_en"
                                       :required="row.active == 1" :isLoading="pageData.editIsLoading"
                                       :cols="[12, 12, 12]">
                                    </GenericInput>
                                 </div>
                                 <div class="flex-grow-1" v-if="header.key == 'number'">
                                    <GenericInput type="number" @input="row.number = $event" :value="row.number"
                                       :required="row.active == 1" :isLoading="pageData.editIsLoading"
                                       :cols="[12, 12, 12]">
                                    </GenericInput>
                                 </div>

                                 <div v-if="header.key == 'active'">
                                    <GenericInput type="switch" :value="row.active" @input="row.active = $event"
                                       :required="false" :isLoading="pageData.editIsLoading" :cols="[3, 3, 3]">
                                    </GenericInput>
                                 </div>
                              </template>
                           </DynamicTable>
                        </v-col>
                     </v-row>


                  </v-card>
               </v-col>
               <v-col cols="12" md="12">
                  <v-card class="shadow py-5 rounded-lg card_img" :key="counter">
                     <v-row class="align-center px-4 py-2 text-capitalize">

                        <v-col cols="12" class="text-capitalize">{{ $t("Picture in the middle") }}</v-col>
                        <AnimatedLoading class="mx-auto" :isLoading="pageData.isLoading" />
                        <v-col cols="12" class="d-flex align-center justify-center mt-12" v-if="!pageData.isLoading">
                           <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                 <div v-bind="attrs" v-on="on">
                                    <PreviewImage :img="form.image" :callBackMethod="(img) => callBackImg('image', img)"
                                       :showDelete="false" :showText="false" :requestDeleteImage="requestDeleteImage" />
                                 </div>
                              </template>
                              <span>{{ '400' + 'x' + '528' }}</span>
                           </v-tooltip>
                        </v-col>

                     </v-row>
                  </v-card>
               </v-col>
            </v-row>

            <v-row>
               <v-col cols="12">
                  <v-card class="shadow py-5 rounded-lg card_analysis">
                     <AnimatedLoading class="mx-auto" :isLoading="pageData.isLoading" />
                     <v-row class="align-center px-4 py-2 text-capitalize" v-if="!pageData.isLoading">
                        <v-col cols="12" class="text-capitalize">{{ $t("the content") }}</v-col>

                        <GenericInput type="text" @input="form.intro_address_ar = $event" :value="form.intro_address_ar"
                           :required="true" label="Introduction title in arabic" :isLoading="pageData.editIsLoading"
                           :cols="[12, 6, 6]">
                        </GenericInput>
                        <GenericInput type="text" @input="form.intro_address_en = $event" :value="form.intro_address_en"
                           :required="true" label="Introduction title in english" :isLoading="pageData.editIsLoading"
                           :cols="[12, 6, 6]">
                        </GenericInput>
                        <GenericInput type="text" @input="form.description_ar = $event" :value="form.description_ar"
                           :required="true" label="description_ar" :isLoading="pageData.editIsLoading"
                           :cols="[12, 12, 12]">
                        </GenericInput>
                        <GenericInput type="text" @input="form.description_en = $event" :value="form.description_en"
                           :required="true" label="description_en" :isLoading="pageData.editIsLoading"
                           :cols="[12, 12, 12]">
                        </GenericInput>
                        <v-col cols="12"><v-divider></v-divider></v-col>

                        <v-row class="text_numbers ">
                           <v-col cols="12" sm="12">
                              <DynamicTable :isLoading="pageData.isLoading" :data="form.content"
                                 :header="pageData.tableHeader" :option="pageData.options" :pageData="pageData"
                                 :printValueMethod="printMethod">

                                 <template v-slot:td="{ row, header }">
                                    <div class="flex-grow-1" v-if="header.key == 'name_ar'">
                                       <GenericInput type="text" @input="row.name_ar = $event" :value="row.name_ar"
                                          :required="row.active == 1" :isLoading="pageData.editIsLoading"
                                          :cols="[12, 12, 12]">
                                       </GenericInput>
                                    </div>
                                    <div class="flex-grow-1" v-if="header.key == 'name_en'">
                                       <GenericInput type="text" @input="row.name_en = $event" :value="row.name_en"
                                          :required="row.active == 1" :isLoading="pageData.editIsLoading"
                                          :cols="[12, 12, 12]">
                                       </GenericInput>
                                    </div>
                                    <div v-if="header.key == 'active'">
                                       <GenericInput type="switch" :value="row.active" @input="row.active = $event"
                                          :required="false" :isLoading="pageData.editIsLoading" :cols="[3, 3, 3]">
                                       </GenericInput>
                                    </div>
                                 </template>
                              </DynamicTable>
                           </v-col>
                        </v-row>

                     </v-row>
                  </v-card>
               </v-col>
            </v-row>

         </v-form>

      </v-container>
   </section>
</template>



<script>
import AvatarProfile from '@/components/ui/AvatarProfile.vue';
import PreviewImage from '@/components/ui/PreviewImage.vue';


export default {
   name: "CasesEntity",

   data: () => ({
      pageData: {
         screen_code: "02-003",
         url: null,
         controlRoute: "website/cases-control",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isTrashed: 1,
         rowsNumber: 15,
         tableHeader: [],
         search: '',
         rows: [],
         options: {},
         page: 1,
         pagination: {},
         rearrangementDialog: false,
      },
      form: {
         image: null,
         intro_address_ar: null,
         intro_address_en: null,
         description_ar: null,
         description_en: null,
         cases: [],
         content: [],
      },
      casesTable: [],
      valid: false,
      logo_image: null,
      language_icon: null,
      logo_image1: null,
      logo_image2: null,
      logo_image3: null,
      center_photo: null,
      counter: 0,
   }),
   components: {
      AvatarProfile,
      PreviewImage
   },
   computed: {

   },
   $route() {
      this.pageMainData();
      this.getData();
   },
   mounted() {
      this.pageMainData();
      this.getData();
   },
   methods: {
      callBackImg(i, img) {
         if (typeof i === 'string') {
            this.form[i] = img
         } else {
            this.form.cases[i].case_image = img;
         }
         if (
            this.$global.FILE_TYPE(img) !== 'png' &&
            this.$global.FILE_TYPE(img) !== 'jpg' &&
            this.$global.FILE_TYPE(img) !== 'svg' &&
            this.$global.FILE_TYPE(img) !== 'jpeg'
         ) {
            this.$store.state.snackbarTitle = this.$i18n.t("Please upload photos only")
            this.$store.state.snackbarType = "info";
            this.$store.state.showSnackbar = true;
            this.requestDeleteImage = true;

            if (typeof i === 'string') {
               this.form[i] = ''
            } else {
               this.form.cases[i].case_image = '';
            }
            img = null;
            this.counter++
         }

      },
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code)?.main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code)?.cat_title;
         this.pageData.entityName = this.$store.state.activeScreen?.sub_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code)?.url;
         this.pageData.tableHeader = [
            // { text: "serial", key: "id", type: 'text', classes: "" },
            { text: "Text in Arabic", key: "name_ar", type: 'slot', classes: "" },
            { text: "Text in English", key: "name_en", type: 'slot', classes: "" },
            { text: "active", key: "active", type: 'slot', classes: "" },
         ]
         this.casesTable = [
            // { text: "serial", key: "id", type: 'text', classes: "" },
            { text: "image", key: "image", type: 'slot', classes: "" },
            { text: "Text in Arabic", key: "name_ar", type: 'slot', classes: "" },
            { text: "Text in English", key: "name_en", type: 'slot', classes: "" },
            { text: "number", key: "number", type: 'slot',classes: "" },
            { text: "status", key: "active", type: 'slot', classes: "" },
         ]

         this.pageData.options = {
            print: this.$global.CheckAction(this.pageData.screen_code, 5),
            delete: this.$global.CheckAction(this.pageData.screen_code, 4),
            edit: this.$global.CheckAction(this.pageData.screen_code, 3),
            restore: this.$global.CheckAction(this.pageData.screen_code, 4),
            view: this.$global.CheckAction(this.pageData.screen_code, 1),
            switch: false,
            selectRow: false,
            sortBy: 'id',
            tableHeader: false,
            searchInput: false,
            printButton: false,
            handleClickRow: false,
         }
      },
      getData() {
         this.pageData.isLoading = true;
         this.$api.GET_METHOD(`case`).then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
               this.form = response.data.items.find(item => item.id === 1)
            }
         })
      },
      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            this.form._method = 'PUT'
            this.$api.POST_METHOD(`case/1`, this.form).then((response) => {
               this.pageData.isLoading = false;
               if (response.check) {
                  console.log('response');
               }
            })
         }
      },
   },
};
</script>

<style lang="scss" scoped>
.card_analysis,
.card_img {
   height: 100%;
}

.text_numbers {
   // height: 60vh;
   // overflow: auto;
}
</style>

