<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid && iconsValidation" :showClose="false"
            :deleteMethod="deleteMethod" />
         <!-- End Of Header -->

         <v-form ref="form" v-model="valid" class="mt-3">
            <!-- headers ar sections -->
            <v-card class="shadow py-5 rounded-lg mt-6">
               <v-row class="align-center px-4 py-2 text-capitalize">
                  <v-col cols="12" class="text-capitalize">{{ $t("Navigation bar") }}</v-col>
                  <v-col cols="12" sm="12">
                     <DynamicTable :isLoading="pageData.isLoading" :data="pageData.rows" :header="pageData.tableHeader"
                        :option="pageData.options" :pageData="pageData" :printValueMethod="printMethod">

                        <template v-slot:td="{ row, header }">
                           <div class="flex-grow-1" v-if="header.key == 'name_ar'">
                              <TextInput :value="row.name_ar" @input="row.name_ar = $event" placeholder="اسم بالعربي"
                                 :required="row.active" :isLoading="false" />
                           </div>
                           <div class="flex-grow-1" v-if="header.key == 'name_en'">
                              <TextInput :value="row.name_en" @input="row.name_en = $event" placeholder="english name"
                                 :required="row.active" :isLoading="false" />
                           </div>
                           <div v-if="header.key == 'type'">
                              <AutoCompleteInput :lookups="types" :value="row.type" placeholder="select"
                                 @input="row.type = $event" label="" :required="row.active" selected_label="name"
                                 selected_prop="id" :isLoading="false" :has_slot="false" />
                           </div>
                           <div v-if="header.key == 'active'">
                              <GenericInput type="switch" :value="row.active" @input="row.active = $event"
                                 :required="false" :isLoading="pageData.editIsLoading" :cols="[3, 3, 3]" />
                           </div>
                        </template>
                     </DynamicTable>
                  </v-col>
               </v-row>
            </v-card>
            <!-- headers ar sections -->

            <!-- logo && language icon ar -->
            <v-card class="shadow py-5 rounded-lg mt-6">
               <AnimatedLoading :isLoading="pageData.isLoading" />
               <v-row class="align-start px-4 py-2" :key="counter" v-show="!pageData.isLoading">
                  <v-col cols="6">
                     <div class="text-capitalize">{{ $t("language icon") }}</div>
                     <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                           <div v-bind="attrs" v-on="on">
                              <AvatarProfile :img="form.lang_logo" :callBackMethod="(img) => callBackImg('lang_logo', img)"
                                 :showDelete="false" :showText="false" :requestDeleteImage="requestDeleteImage" />
                           </div>
                        </template>
                        <span>{{ '100' + 'x' + '100' }}</span>
                     </v-tooltip>
                  </v-col>

                  <v-col cols="6">
                     <div class="text-capitalize">{{ $t("Logo") }}</div>
                     <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                           <div v-bind="attrs" v-on="on">
                              <AvatarProfile :img="form.logo" :callBackMethod="(img) => callBackImg('logo', img)"
                                 :showDelete="false" :showText="false" :width="200"
                                 :requestDeleteImage="requestDeleteImage" />
                           </div>
                        </template>
                        <span>{{ '34' + 'x' + '34' }}</span>
                     </v-tooltip>
                  </v-col>
               </v-row>
            </v-card>
            <!-- logo && language icon ar -->

         </v-form>

      </v-container>
   </section>
</template>



<script>
import AvatarProfile from '@/components/ui/AvatarProfile.vue';

export default {
   name: "HeaderEntity",

   data: () => ({
      pageData: {
         screen_code: "02-001",
         url: null,
         controlRoute: "website/header-control",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isTrashed: 1,
         rowsNumber: 15,
         tableHeader: [],
         search: '',
         rows: [],
         options: {},
         page: 1,
         pagination: {},
         rearrangementDialog: false,
      },
      types: [],
      valid: false,
      requestDeleteImage: false,
      counter: 0,
      form: {
         lang_logo: null,
         logo: null,
         menu: []
      },

   }),
   components: {
      AvatarProfile

   },
   computed: {
      iconsValidation() {
         return this.form.lang_logo !== null && this.form.logo !== null
      }
   },
   watch: {
      $route() {
         this.queryParams();
         this.pageMainData();
         this.getData();
      },
   },
   mounted() {
      this.pageMainData();
      this.getData();
      this.types = [
         { id: 1, name: this.$t('section in home page') },
         { id: 2, name: this.$t('consultations page') },
         { id: 3, name: this.$t('blog page') }]
   },
   methods: {
      callBackImg(form_key, img) {
         this.form[form_key] = img;
         if (
            this.$global.FILE_TYPE(img) !== 'png' &&
            this.$global.FILE_TYPE(img) !== 'jpg' &&
            this.$global.FILE_TYPE(img) !== 'svg' &&
            this.$global.FILE_TYPE(img) !== 'jpeg'
         ) {
            this.$store.state.snackbarTitle = this.$i18n.t("Please upload photos only")
            this.$store.state.snackbarType = "info";
            this.$store.state.showSnackbar = true;
            this.requestDeleteImage = true;
            this.form[form_key] = null;
            img = null;
            this.counter++
         }
      },
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code)?.main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code)?.cat_title;
         this.pageData.entityName = this.$store.state.activeScreen?.sub_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code)?.url;
         this.pageData.tableHeader = [
            // { text: "serial", key: "id", type: 'text', classes: "" },
            { text: "name_ar", key: "name_ar", type: 'slot', classes: "" },
            { text: "name_en", key: "name_en", type: 'slot', classes: "" },
            { text: "type", key: "type", type: 'slot', classes: "" },
            { text: "active", key: "active", type: 'slot', classes: "" },
         ]
         this.pageData.options = {
            print: this.$global.CheckAction(this.pageData.screen_code, 5),
            delete: this.$global.CheckAction(this.pageData.screen_code, 4),
            edit: this.$global.CheckAction(this.pageData.screen_code, 3),
            restore: this.$global.CheckAction(this.pageData.screen_code, 4),
            view: this.$global.CheckAction(this.pageData.screen_code, 1),
         }
      },
      getData() {
         this.pageData.isLoading = true;
         this.$api.GET_METHOD(`header`).then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
               this.pageData.entityName = this.pageData.isTrashed == 0 ? this.$i18n.t("deleted from") + " " + this.$store.state.activeScreen.sub_title : this.$store.state.activeScreen.sub_title;
               this.pageData.rows = response.data.items[0].menu;
               this.form = response.data.items[0]
            }
         })
      },
      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            this.form._method = 'PUT'
            this.$api.POST_METHOD(`header/1`, this.form).then((response) => {
               this.pageData.isLoading = false;
               if (response.check) {
                  console.log('response');
               }
            })
         }
      },

   },
};
</script>
