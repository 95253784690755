<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid" :deleteMethod="deleteMethod" />
         <!-- End Of Header -->

         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3">
            <v-card class="backgroundW shadow pa-5 pt-7">
               <v-row align="center">
                  <GenericInput type="text" :value="form.screen_main_title_en"
                     @input="form.screen_main_title_en = $event" label="name_en" :required="true"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]"></GenericInput>
                  <GenericInput type="text" :value="form.screen_main_title_ar"
                     @input="form.screen_main_title_ar = $event" label="name_ar" :required="true"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]"></GenericInput>
                     <GenericInput
                     type="dropzone"
                     v-if="pageData.isEdit == true"
                     :value="form.screen_main_image || form.screen_main_image_edit"
                     @input="form.screen_main_image_edit = $event"
                     label="icon"
                     :required="true"
                     :isLoading="pageData.editIsLoading"
                     :cols="[12, 12, 12]"
                  ></GenericInput>
                  <GenericInput
                     type="dropzone"
                     v-if="pageData.isEdit == false"
                     :value="form.screen_main_image"
                     @input="form.screen_main_image = $event"
                     label="icon"
                     :required="true"
                     :isLoading="pageData.editIsLoading"
                     :cols="[12, 12, 12]"
                  ></GenericInput>
               </v-row>
            </v-card>
         </v-form>
         <DeleteConfirmation v-if="deleteDialog == true" :dialog="deleteDialog" :item="form.name ? form.name : null"
            :backValueMethod="deleteMethod"></DeleteConfirmation>
         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>

import GenericInput from '@/components/ui/GenericInput.vue';
import DeleteConfirmation from "@/components/modals/DeleteConfirmation.vue";
import ControlHeader from "@/components/ui/ControlHeader.vue";
export default {
   name: "MainMenuControlVue",

   data: () => ({
      pageData: {
         screen_code: "01-001",
         url: null,
         controlRoute: "/main/screen_main-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      deleteDialog: false,
      valid: false,
      form: {
         screen_main_title_en: null,
         screen_main_title_ar: null,
         screen_main_image: null,
      },
   }),
   components: {
      GenericInput,
      DeleteConfirmation,
      ControlHeader
   },
   computed: {

   },
   mounted() {
      this.pageMainData()
      this.getData()
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.pageData.entityName = this.$route.params.id ? this.$i18n.t("edit") + " " + this.$i18n.t('main menu') : this.$i18n.t("add") + " " + this.$i18n.t('Main menu');
      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`screen_main/${this.$route.params.id}`).then((response) => {
                  if (response.check) {
                     this.pageData.editIsLoading = false;
                     this.pageData.isLoading = false;
                     console.log("response", response);
                     this.form = response.data.data;
                     this.$refs.form.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {

               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`screen_main/create`).then((response) => {
                  if (response.check) {
                     this.pageData.isLoading = false;
                     this.account_trees = response.data.account_tree;
                     this.branchs = response.data.branchs;
                     this.banks = response.data.banks;
                  }
               })
            } else {
               this.$router.push('/')
            }

         }

      },
      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               if (this.form.screen_main_image_edit) {
                  this.form.screen_main_image = this.form.screen_main_image_edit;
                  delete this.form.screen_main_image_edit;
               } else {
                  delete this.form.screen_main_image;
                  delete this.form.screen_main_image_edit;
               }
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`screen_main/${this.form.screen_main_id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push('/main/screen_main')
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`screen_main`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push('/main/screen_main')
                  }
               })
            }
         }
      },
      deleteMethod(status) {
         this.deleteDialog = false
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`screen_main/${this.form.screen_main_id}`, { _method: 'delete' }).then(() => {
               this.$router.push('/main/screen_main');
               this.pageData.isLoading = false
            })
         }
      },


   },
};
</script>
