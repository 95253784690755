<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid" :deleteMethod="deleteMethod" />
         <!-- End Of Header -->
         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-5">
            <v-card class="backgroundW shadow pa-5 pt-7">
               <v-row align="center">
                  <GenericInput type="text" :value="form.title_ar" @input="form.title_ar = $event" label="title_ar"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />
                  <GenericInput type="text" :value="form.title_en" @input="form.title_en = $event" label="title_en"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />

                  <GenericInput type="combobox" :value="form.keywords_ar" @input="form.keywords_ar = $event"
                     label="keywords_ar" selected_label="name" selected_prop="id" :multi="false" :required="true"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />

                  <GenericInput type="combobox" :value="form.keywords_en" @input="form.keywords_en = $event"
                     label="keywords_en" selected_label="name" selected_prop="id" :multi="false" :required="true"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />

                  <GenericInput type="text" :value="form.description_ar" @input="form.description_ar = $event"
                     label="description_ar" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]" />

                  <GenericInput type="text" :value="form.description_en" @input="form.description_en = $event"
                     label="description_en" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]" />

               </v-row>

            </v-card>
         </v-form>
         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>
import GenericInput from '@/components/ui/GenericInput.vue';
import ControlHeader from "@/components/ui/ControlHeader.vue";
export default {
   name: "SEOControl",
   data: () => ({
      pageData: {
         screen_code: "02-007",
         url: null,
         controlRoute: "website/seo-control",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      valid: false,
      form: {
         title_ar: null,
         title_en: null,
         keywords_ar: '',
         keywords_en: '',
         description_ar: null,
         description_en: null
      }
   }),
   components: {
      ControlHeader,
      GenericInput,
   },
   computed: {

   },
   watch: {

   },
   mounted() {
      this.pageMainData();
      this.getData();
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.pageData.entityName = this.$route.params.id ? this.$i18n.t("edit") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title : this.$i18n.t("add") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title;
      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`seo/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.form = response.data.data;
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {

               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`seo/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.seo_pages = response.data.seo_pages;
                     this.types = response.data.seo_types;
                  }
               })
            } else {
               this.$router.push('/')
            }

         }

      },
      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`seo/${this.form.id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`seo`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`seo/${this.form.id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },


   },
};
</script>
