<template>
  <div>
    <div class="image-input" :style="`width:${width}px;height:${height}px;`" @click="chooseImage">
      <div class="image-container">
        <!-- default image -->
        <img v-if="!img && !image_is_changed" :src="defaultImag" />
        <!-- Live Image -->
        <img v-if="img" :src="image_is_changed || isBase64(img) ? img : $api.serverUrl + img" />
      </div>
      <input class="file-input" ref="fileInput" type="file" :accept="fileType" @input="image_method">
    </div>
    <p class="mt-5" v-if="showText && !img">{{ $t(text) | capitalize }}</p>
    <div class="d-flex justify-center text-center">
      <v-btn class="mt-5 mx-auto text-capitalize" color="error--text" elevation="0" v-if="showDelete && img"
        @click="deleteImage">
        {{ $t('delete image') }}</v-btn>
    </div>

  </div>
</template>


<script>
export default {
  name: "AvatarProfile",
  data: () => ({
    image_is_changed: false,
  }),
  props: {
    width: { default: '112' },
    height: { default: '112' },
    showText: { default: true },
    showDelete: { default: false },
    text: { default: 'personal picture' },
    classes: { default: 'image-input' },
    img: { default: null },
    defaultImag: { default: require('@/assets/img/svg/profileBig.svg') },
    callBackMethod: { type: Function },
    requestDeleteImage: { default: false },
    fileType: { default: 'image/*' },
  },
  computed: {
    isBase64() {
      return (data) => data.substring(0, 4) === 'data'
    }
  },
  methods: {
    image_method() {
      this.image_is_changed = true;
      const input = this.$refs.fileInput
      const files = input.files
      if (files && files[0]) {
        const reader = new FileReader
        reader.onload = e => {
          this.callBackMethod(e.target.result, this.image_is_changed)
        }
        reader.readAsDataURL(files[0])
        this.$emit('input', files[0])
      }

      input.value = null
    },
    chooseImage() {
      this.$refs.fileInput.click()
    },
    deleteImage() {
      this.img = null
      this.image_is_changed = false
      this.callBackMethod(this.img)
    },

  },
  watch: {
    requestDeleteImage() {
      if(this.requestDeleteImage){
        this.deleteImage()
        this.requestDeleteImage = false
      }
    }
  }


};
</script>



<style scoped lang="scss">
.image-input div img {
  width: 100%;
  height: 100%;
  // border-radius: 50%;
  object-fit: contain;
}

.image-input {
  display: block;
  cursor: pointer;
  border-radius: 50%;
  background-size: 100% 100%;
  background-position: center center;
  margin: auto;
  z-index: 1;
}

.image-container {
  width: 100%;
  height: 100%;
  background-color: #eef4fc;
}

.file-input {
  display: none
}
</style>
