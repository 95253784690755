<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <EntityHeader :pageData="pageData" :getData="getData" :getSearch="getSearch" :createBtn="false"
        :showDeleted="false">
        <template slot="leftSide">

          <v-sheet height="70">
            <v-toolbar flat>

              <v-btn fab text small color="" @click="prev">
                <v-icon>
                  {{ $vuetify.rtl ? 'mdi-chevron-right' : 'mdi-chevron-left' }}
                </v-icon>
              </v-btn>

              <v-toolbar-title v-if="$refs.calendar" class="text-center"
                :style="$vuetify.rtl ? 'width:135px' : 'width:150px'">
                {{ $refs.calendar.title }}
              </v-toolbar-title>

              <v-btn fab text small color="" @click="next">
                <v-icon>
                  {{ $vuetify.rtl ? 'mdi-chevron-left' : 'mdi-chevron-right' }}
                </v-icon>
              </v-btn>

              <!-- rest time  -->
              <!-- <v-btn outlined class="mr-4" color="grey darken-2" @click="setMonth">
                {{ $t('month') }}
              </v-btn> -->
            </v-toolbar>
          </v-sheet>

        </template>
      </EntityHeader>
      <!-- End Of Header -->
    
      <v-row class="fill-height" style="z-index:100000" v-if="!pageData.isLoading">
        <v-col>

          <v-sheet height="600">
            <v-calendar ref="calendar" v-model="focus" color="primary" :events="events" :type="type" first-time="09:00:00"
              :interval-count="24" @click:event="showEvent" @click:more="viewDay" @click:date="viewDay"
              @change="updateRange" :event-height="90" :locale="$vuetify.rtl ? 'ar' : 'en-UK'">
              <!-- :style="event.date < currentDate ? ' cursor:default !important' : 'cursor:pointer !important'" -->
              <template v-slot:event="{ event }">
                <!-- :disabled="event.date < currentDate ? true : false" -->
                <v-card class="ps-2 mt-2" flat @click.stop >
                  <div class="font-weight-bold">{{ event.date }}</div>
                  <v-card v-if="event.hasTimes" @click="hasScheduled(event)" style="background-color:  #4d77ff !important"
                    :height="event.hasReserve ? '25' : '55'" class="d-flex justify-center align-center mt-1 white--text"
                    flat>
                    <span class="pa-0 ma-0">{{ $t('An appointment has been scheduled') }}</span>
                  </v-card>
                  <v-card v-if="event.hasReserve" @click="reservation(event)"
                    style="background-color:  #FC4DA8 !important" height="25"
                    class="d-flex justify-center align-center mt-2 white--text" flat>
                    <span class="pa-0 ma-0">{{ $t('There is a reservation') }}</span>
                  </v-card>
                </v-card>
              </template>

              <template v-slot:interval="{ time, date }">
                <v-btn block depressed @click="addReservation(date, time)" class="light">
                  {{ $t('add reservation') }}
                </v-btn>
              </template>
            </v-calendar>


          </v-sheet>

        </v-col>
      </v-row>

      <HasScheduledDialog :openDialog="has_scheduled_dialog"
        :closeDialog="() => (has_scheduled_dialog = false, data = {})" :data="data" :updateBackGround="getData" />

      <ReservationDialog :openDialog="reservation_dialog" :closeDialog="() => (reservation_dialog = false, data = {})"
        :data="data" :updateBackGround="getData" />


      <AnimatedLoading class="mt-16  pa-16" :isLoading="pageData.isLoading" height="70" :text="false" :hideText="false" />
    </v-container>
  </section>
</template>

<script>

import HasScheduledDialog from "./HasScheduledDialog.vue";
import ReservationDialog from "./ReservationDialog.vue";


export default {
  name: "bookingEntity",

  data: () => ({
    pageData: {
      screen_code: "03-002",
      url: null,
      controlRoute: "consulting/interviews-control",
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
    },

    data: {},
    activeDate: null,
    focus: '',
    type: 'month',
    value: '',
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    reservation_dialog: false,
    has_scheduled_dialog: false,
    events: [],
    colors: ['#FC4DA8', '#4d77ff'],
    names: ['An appointment has been scheduled', 'There is a reservation'],
  }),
  mounted() {
    this.activeDate = this.$global.GetCurrentDate()
    this.pageMainData();
    this.getData();
    this.$refs.calendar.checkChange()
  },

  computed: {
    currentDate() {
      const now = new Date();
      const formattedDate = `${now.getFullYear()}-${(now.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}`;
      return formattedDate;
    },
  },
  methods: {
    pageMainData() {
      this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
      this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
      this.pageData.entityName = this.$global.FilterPermissions(this.pageData.screen_code).sub_title;
      this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
    },

    getSearch(word) {
      this.pageData.search = word ? word : "";
      this.getData();
    },

    getData() {
       if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
        let year = new Date(this.activeDate).getFullYear();
        let month = new Date(this.activeDate).getMonth() + 1;
        this.$api.GET_METHOD(`available_time?word=${this.pageData.search || ""}&month=${month}`)
        .then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              let events = []
              console.log(this.$global.GetDaysBetween(`${year}-${month}-01`, `${year}-${month}-31`));
              let daysBetween = this.$global.GetDaysBetween(`${year}-${month}-01`, `${year}-${month}-31`)
              daysBetween.forEach(day => {
                console.log('day', response.data.items);
                let hasReserve = response.data.items[day]?.some(e => e.reserved == 1)
                let hasTimes = response.data.items[day]?.length
                events.push({
                  start: `${day} 00:00`,
                  date: day,
                  hasReserve: hasReserve,
                  hasTimes: hasTimes,
                  color: 'transparent',
                })
              });

              this.events = events;
              this.$refs.calendar.checkChange();
            }
          });

      } else {
        this.$router.push("/");
      }
    },

     viewDay({ date }) {
      this.focus = date
      this.type = 'day';
      console.log('date', date);
    },
    getEventColor(event) {
      return event.color
    },
    setMonth() {
      this.type = 'month'
    },
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },

    showEvent({ nativeEvent, event }) {
      this.selectedElement = nativeEvent.target
      this.selectedEvent = event
      this.data = this.selectedEvent
      console.log('this.data', this.data);
      requestAnimationFrame(() => requestAnimationFrame(() => {
          this.has_scheduled_dialog = true
      }));


      nativeEvent.stopPropagation()
    },
    hasScheduled(event) {
      this.selectedEvent = event
      this.data = event
      console.log('this.data', this.data);

      this.has_scheduled_dialog = true

    },

    reservation(event) {
      this.selectedEvent = event
      this.data = event
      console.log('this.data', this.data);
      this.reservation_dialog = true
    },

    addReservation(date, time) {
      this.reservation_dialog = true;
      this.data = {
        new_reservation_date: `${date} ${time}:00`,
        date: date,
        time: time,
      }
    },
    updateRange(range) {
      this.activeDate = range.start.date;
      this.getData()
    },

  },


  components: {
    ReservationDialog,
    // Calendar,
    HasScheduledDialog,
  },
};
</script>

<style scoped lang="scss">
.theme--light.v-sheet {
  background-color: transparent !important;
 
}
</style>