import { render, staticRenderFns } from "./PermissionsEntity.vue?vue&type=template&id=8843ac10&"
import script from "./PermissionsEntity.vue?vue&type=script&lang=js&"
export * from "./PermissionsEntity.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports