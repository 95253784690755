<template>
    <v-dialog v-model="openDialog" @click:outside="close" :retain-focus="false" max-width="900">
        <v-form ref="form" v-model="valid">
            <v-card class="shadow pa-5 card-style" :key="id">

                <section v-if="!isLoading">
                    <v-row align="center">

                        <v-col cols="12">
                            <div class="d-flex justify-space-between px-5">
                                <h5> {{ $t('The appointment is on') | capitalize }} {{ data.date }}</h5>
                                <v-btn class="error--text" icon depressed @click="close">
                                    <v-icon size="24" color="error"> mdi-close</v-icon>
                                </v-btn>
                            </div>
                        </v-col>

                        <v-col cols="12" class="scrollBar">
                            <v-card class="main-card-style shadow-none pa-3 mb-8" elevation="0" v-for="card in ArrayData"
                                :key="card.id">
                                <v-row>
                                    <v-col cols="12" lg="1">
                                        <v-img lazy-src="@/assets/img/png/has_scheduled_icon.png" max-width="100"
                                            :src="$global.endpointURL() + card.user_image"></v-img>

                                    </v-col>
                                    <v-col cols="12" lg="11" class="py-7 px-8">

                                        <div class="d-flex justify-space-between my-2 pa-0">
                                            <h4>{{ card.time || "-" }}</h4>
                                            <h4>{{ card.amount || 0 }}</h4>
                                        </div>

                                        <div class="d-flex aligh-center  my-3 pa-0">
                                            <img src="@/assets/img/png/Shopping.png" alt="Shopping" class="ms-n2">
                                            <span class="mx-4 mt-1">{{ card.consultation_name || "-" }}</span>
                                        </div>

                                        <div class="d-flex aligh-center  my-3 pa-0">
                                            <img src="@/assets/img/png/shopkeeper.png" alt="shopkeeper" class="ms-n2">
                                            <span class="mx-4 mt-1" v-if="card.type == 1">
                                                {{ $t('in the office') || "-" }}
                                            </span>
                                            <span class="mx-4 mt-1" v-if="card.type == 2"> {{ $t('online') || "-" }}</span>
                                        </div>

                                        <div class="d-flex aligh-center  my-3 pa-0">
                                            <img src="@/assets/img/png/Person.png" alt="Person" class="ms-n2">
                                            <h5 class="mx-4 mt-1">{{ card.user_full_name || "-" }}</h5>

                                            <span class="d-flex align-center">
                                                <img src="@/assets/img/png/Home.png" alt="Home" class="">
                                                <span class="mx-4 caption">{{ card.company_name || "-" }}</span>
                                            </span>

                                        </div>
                                        <div class="d-flex aligh-center  my-4 pa-0">
                                            <v-tooltip bottom :color="snackbar ? 'transparent' : ''">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <h5 class="underLine cursor_pointer" v-bind="attrs" v-on="on"
                                                        @click="copyToClipboard(card.mobile)">{{ card.mobile }}</h5>
                                                </template>
                                                <span v-if="!snackbar">{{ $t('Click to copy') }}</span>
                                            </v-tooltip>

                                            <v-tooltip bottom :color="snackbar ? 'transparent' : ''">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <h5 class="underLine cursor_pointer mx-3" v-bind="attrs" v-on="on"
                                                        @click="copyToClipboard(card.email)">
                                                        {{ card.email }}
                                                    </h5>
                                                </template>
                                                <span v-if="!snackbar">{{ $t('Click to copy') }}</span>
                                            </v-tooltip>
                                        </div>
                                    </v-col>

                                    <!-- Cancel button -->
                                    <v-col cols="12" class="d-flex justify-end">
                                        <v-btn v-if="card.status == 1" depressed :disabled="false" @click="edit(card)"
                                            height="45" width="200"
                                            class="rounded-lg font-weight-bold blue6 white--text mx-3"
                                            :loading="saveDataLoading">
                                            {{ $t('edit') }}
                                        </v-btn>
                                        <v-btn depressed @click="card.status == 1 ? endReservation(card) : null" height="45"
                                            width="200" class="rounded-lg font-weight-bold "
                                            :class="card.status == 1 ? 'white--text' : 'green--text'"
                                            :color="card.status == 1 ? 'success' : 'transparent'" :loading="saveDataLoading">
                                            {{ card.status == 1 ? $t('Complete your reservation') : $t('booking done') }}
                                        </v-btn>

                                    </v-col>
                                </v-row>
                            </v-card>

                            <div v-if="ArrayData.length == 0 && !isLoading" class="my-3">
                                <img src="@/assets/img/svg_icons/no_data.svg" height="150" class="d-block my-3 mx-auto"
                                    alt="booking">
                                <div class="text-h6 text-center  gray8--text">{{ $t('you havent entered anything yet') |
                                    capitalize }}</div>
                            </div>
                        </v-col>


                    </v-row>
                </section>
                <AnimatedLoading class="mt-10  pa-5" :isLoading="isLoading" height="70" :text="false" :hideText="false" />
            </v-card>


            <v-snackbar v-model="snackbar">
                {{ text }}
                <template v-slot:action="{ attrs }">
                    <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
                        {{ $t('close') }}
                    </v-btn>
                </template>
            </v-snackbar>

            <ReservationEditDialog :openDialog="reservation_edit"
                :closeDialog="() => (reservation_edit = false, cardData = {})" :cardData="cardData" :data="data"
                :updateBackGround="getData" :updateMainBackground="updateBackGround" />
        </v-form>

    </v-dialog>
</template>
<script>
import ReservationEditDialog from "./ReservationEditDialog.vue";

export default {

    name: "HasScheduledDialog",

    data() {
        return {
            valid: null,
            id: null,
            isLoading: false,
            saveDataLoading: false,
            dialogTitle: '',
            timeSlots: [],
            ArrayData: [],
            cardData: {},
            snackbar: false,
            reservation_edit: false,
            text: ``,
            form: {
                time: null
            }

        };
    },

    mounted() {
        this.timeSlots = [
            {
                id: 1,
                time: '7:00',
            },
            {
                id: 2,
                time: '8:00',
            },
            {
                id: 3,
                time: '9:00',
            },
            {
                id: 4,
                time: '10:00',
            },
            {
                id: 5,
                time: '11:00',
            },
            {
                id: 6,
                time: '13:00',
            },
            {
                id: 7,
                time: '14:00',
            },
            {
                id: 8,
                time: '15:00',
            },
            {
                id: 9,
                time: '16:00',
            },
            {
                id: 10,
                time: '17:00',
            },

            {
                id: 11,
                time: '19:00',
            },
            {
                id: 12,
                time: '20:00',
            },
            {
                id: 13,
                time: '21:00',
            },
            {
                id: 14,
                time: '22:00',
            },


        ];
    },

    computed: {

    },

    methods: {

        getData(applayLoading = true) {
            applayLoading && (this.isLoading = true);
            this.$api.GET_METHOD(`booking?date=${this.data.date}`).then((response) => {
                this.isLoading = false;
                if (response.check) {
                    console.log("response", response);
                    this.ArrayData = response.data.items
                    // Object.keys(this.form).forEach(key => {
                    //     this.form[key] = response.data.items[key]
                    // });
                }
            })



        },

        endReservation(card) {
            this.saveDataLoading = true;
            console.log('card', card);
            this.$api.POST_METHOD(`finish_booking/${card.id}`, { _method: 'PUT' }).then((response) => {
                this.saveDataLoading = false;
                if (response.check) {
                    this.getData(false)
                    this.updateBackGround(false)
                    // this.close()
                }
            })
        },

        close() {
            Object.keys(this.form).forEach(key => this.form[key] = null);
            this.id = 0
            this.snackbar = false
            this.closeDialog()
        },

        selectedTime(time) {
            this.form.time = time;
        },

        async copyToClipboard(text) {
            try {
                await navigator.clipboard.writeText(text);
                this.snackbar = true;
                this.text = `${text} ${this.$t('has been copied')}`;
                setTimeout(() => {
                    this.snackbar = false;
                }, 2500);
            } catch (err) {
                console.error('Unable to copy to clipboard', err);
                this.snackbar = true;
                this.text = this.$i18n.t('Failed to copy to clipboard');
                setTimeout(() => {
                    this.snackbar = false;
                }, 2500);
            }
        },


        edit(data) {
            this.cardData = data
            this.reservation_edit = true
        }
    },

    watch: {
        openDialog() {
            if (this.openDialog) {
                this.id = +1
                this.getData()
            }
        },

    },


    props: {
        openDialog: { default: false },
        closeDialog: { type: Function },
        updateBackGround: { type: Function },
        data: { default: Object },
    },

    components: { ReservationEditDialog }
}
</script>

<style lang="scss" scoped>
.scrollBar {
    overflow: auto;
    white-space: nowrap;
    max-height: 80dvh;
}


.card-style {
    border-radius: var(--3, 1rem);
    background: var(--gray-12, #F9F9F9);
    box-shadow: 0px -9px 16px 0px rgba(0, 0, 0, 0.13) inset;
    backdrop-filter: blur(100px);
}

.main-card-style {
    border-radius: 1rem !important;
    background: var(--White, #FFF);
}


.main-card-style .underLine {
    color: blue;
    text-decoration: underline;
    font-weight: bold;
}

.scrollBar::-webkit-scrollbar {
    width: 2px;
}

.scrollBar::-webkit-scrollbar-thumb {
    background-color: var(--primary);
}
</style>