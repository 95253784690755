

// HR Module Routes:-
// import MainPageVue from "@/views/WebsiteModule/MainPage/MainPage.vue";
import MainPageVue from "../views/WebsiteModule/MainPage/MainPage.vue";

// Child's
import SettingsModule from "../views/SettingsModule/SettingsModule.vue";
import ConsultingModule from "../views/ConsultingModule/ConsultingModule.vue";

import { SettingsModuleChildrens } from "./SettingsModuleRoutes";
import { ConsultingModuleChildrens } from "./ConsultingModuleRoutes";



import HeaderEntity from "../views/WebsiteModule/Header/HeaderEntity";

import FooterEntity from "../views/WebsiteModule/Footer/FooterEntity.vue";

import SlidersEntity from "../views/WebsiteModule/Sliders/SlidersEntity.vue";

import CasesEntity from "@/views/WebsiteModule/Cases/CasesEntity";

import ServicesEntity from "@/views/WebsiteModule/Services/ServicesEntity";

import AboutUsEntity from "@/views/WebsiteModule/AboutUs/AboutUsEntity";

import ContactUsEntity from "@/views/WebsiteModule/ContactUs/ContactUsEntity";

import CustomerContactsEntity from "@/views/WebsiteModule/CustomerContact/CustomerContactsEntity";

import SEOEntity from "@/views/WebsiteModule/SEO/SEOEntity";
import SEOControl from "@/views/WebsiteModule/SEO/SEOControl";


export const WebsiteModuleRoutes = [
  {
    path: "/settings",
    name: "SettingsModule",
    component: SettingsModule,
    children: [...SettingsModuleChildrens],
  },

  {
    path: "/consulting",
    name: "ConsultingModule",
    component: ConsultingModule,
    children: [...ConsultingModuleChildrens],
  },

  {
    path: "/",
    name: "MainPage",
    component: MainPageVue,
    meta: {
      title: "home",
    },
  },

  // Header
  {
    path: "/website/header",
    name: "HeaderEntity",
    component: HeaderEntity,
    meta: {
      screen_code: "02-001",
    },
  },
  // Footer
  {
    path: "/website/footer",
    name: "FooterEntity",
    component: FooterEntity,
    meta: {
      screen_code: "02-002",
    },
  },
  // Sliders
  {
    path: "/website/sliders",
    name: "SlidersEntity",
    component: SlidersEntity,
    meta: {
      screen_code: "02-008",
    },
  },
  // Section Two (Cases)
  {
    path: "/website/cases",
    name: "CasesEntity",
    component: CasesEntity,
    meta: {
      screen_code: "02-003",
    },
  },
  // Section Three (Services)
  {
    path: "/website/services",
    name: "ServicesEntity",
    component: ServicesEntity,
    meta: {
      screen_code: "02-004",
    },
  },
  // Section Four (about Us)
  {
    path: "/website/about_us",
    name: "AboutUsEntity",
    component: AboutUsEntity,
    meta: {
      screen_code: "02-005",
    },
  },
  // Section Five (Contact Us)
  {
    path: "/website/contact_us",
    name: "ContactUsEntity",
    component: ContactUsEntity,
    meta: {
      screen_code: "02-006",
    },
  },
  // Customer Contacts
  {
    path: "/website/customer_contacts",
    name: "CustomerContactsEntity",
    component: CustomerContactsEntity,
    meta: {
      screen_code: "02-009",
    },
  },
  // SEO
  {
    path: "/website/seo",
    name: "SEOEntity",
    component: SEOEntity,
    meta: {
      screen_code: "02-007",
    },
  },
  {
    path: "/website/seo-control",
    name: "SEOControlADD",
    component: SEOControl,
    meta: {
      screen_code: "02-007",
    },
  },
  {
    path: "/website/seo-control/:id",
    name: "SEOControlEdit",
    component: SEOControl,
    meta: {
      screen_code: "02-007",
    },
  },

  


];
