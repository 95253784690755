import Vue from "vue";
import vuetify from "vuetify/lib/framework";
import ar from "../locale/ar.json";
import en from "../locale/en.json";
import '@mdi/font/css/materialdesignicons.css'; 
Vue.use(vuetify);

export default new vuetify({
  rtl: localStorage.getItem("language") == "en" ? false : true,
  lang: {
    locales: { en, ar },
    current: localStorage.getItem("language") == "en" ? en : ar,
    //  current: "ar",
  },
  icons: {
    iconfont: "mdi",
  },
  theme: {
    dark: localStorage.getItem("darkTheme") == "true" ? true : false,
    customProperties: true,
    themes: {
      light: {
        primary: "#236cc8",
        secondary: "#002ed0",
        accent: "#b570e4",
        error: "#d40404",
        info: "#3363ff",
        success: "#009f52",
        warning: "#ea8208",
        backgroundD: "#1e1e1e",
        backgroundW: "#fff",
        light: "#f9f9f9",

        // prototype colors:
        rowUp: "#daf3ea",
        rowDown: "#f8d0ce",

        gray1:  "#161616",
        gray2:  "#222222",
        gray3:  "#2e2e2e",
        gray4:  "#424242",
        gray5:  "#5b5b5b",
        gray6:  "#77757f",
        gray7:  "#838383",
        gray8:  "#a7a6a6",
        gray9:  "#b9b9b9",
        gray10: "#d2d2d2",
        gray11: "#ebebeb",
        gray12: "#f2f2f2",

        blue1: "#05101c",
        blue2: "#0d2747",
        blue3: "#143e72",
        blue4: "#1e5eab",
        blue5: "#5896e1",
        blue6: "#1e5eab",
        blue7: "#8ab5ea",
        blue8: "#bcd5f3",
        blue9: "#6094d7",
        blue10: "#adc8eb",
        blue11: "#eef4fc",
        blue12: "#eef4fc",

        green1:  "#002212",
        green2:  "#004423",
        green3:  "#006635",
        green4:  "#008846",
        green5:  "#008846",
        green6:  "#00b75f",
        green7:  "#00ea79",
        green8:  "#81ffc2",
        green9:  "#cdffe7",
        green10: "#bcf8e9",
        green11: "#d2fbf0",
        green12: "#e9fdf8",

        yellow1:  "#321b02",
        yellow2:  "#633704",
        yellow3:  "#a55b06",
        yellow4:  "#c66d07",
        yellow5:  "#f7921f",
        yellow6:  "#f9ae57",
        yellow7:  "#ffeb98",
        yellow8:  "#f9ae57",
        yellow9:  "#fbc98f",
        yellow10: "#feedda",
        yellow11: "#fff8dd",
        yellow12: "#fffcee",

        red1:  "#3c0101",
        red2:  "#5a0202",
        red3:  "#870303",
        red4:  "#b40404",
        red5:  "#fa0707",
        red6:  "#f76969",
        red7:  "#fc5959",
        red8:  "#fd9797",
        red9:  "#fba9a9",
        red10: "#FCBFBF",
        red11: "#FDD4D4",
        red12: "#feeaea",

        orange5: "#ff8b34",
        lavender: "#E5CDF5",
      },
      dark: {
        primary: "#236cc8",
        secondary: "#002ed0",
        accent: "#b570e4",
        error: "#d40404",
        info: "#3363ff",
        success: "#009f52",
        warning: "#ea8208",
        backgroundD: "#fff",
        backgroundW: "#1e1e1e",
        light: "#181818",

        // prototype colors:
        rowUp: "#daf3ea",
        rowDown: "#f8d0ce",

        gray1: "#fafafa",
        gray2: "#f2f2f2",
        gray3: "#ebebeb",
        gray4: "#d2d2d2",
        gray5: "#b9b9b9",
        gray6: "#a7a6a6",
        gray7: "#838383",
        gray8: "#5b5b5b",
        gray9: "#424242",
        gray10: "#2e2e2e",
        gray11: "#161616",
        gray12: "#161616",

        blue1: "#0030cc",
        blue2: "#0036e6",
        blue3: "#003cff",
        blue4: "#1a50ff",
        blue5: "#3363ff",
        blue6: "#4d77ff",
        blue7: "#668aff",
        blue8: "#809eff",
        blue9: "#99b1ff",
        blue10: "#b3c5ff",
        blue11: "#ccd8ff",
        blue12: "#e6ecff",

        green1: "#cdffe7",
        green2: "#81ffc2",
        green3: "#00ea79",
        green4: "#00b75f",
        green5: "#4bedc5",
        green6: "#008846",
        green7: "#006635",
        green8: "#004423",
        green9: "#002212",
        green10: "#bcf8e9",
        green11: "#d2fbf0",
        green12: "#e9fdf8",
        green13: "#18BA92",

        yellow1: "#feedda",
        yellow2: "#fbc98f",
        yellow3: "#f9ae57",
        yellow4: "#f7921f",
        yellow5: "#ffe576",
        yellow6: "#c66d07",
        yellow7: "#a55b06",
        yellow8: "#633704",
        yellow9: "#321b02",
        yellow10: "#fff5cc",
        yellow11: "#fff8dd",
        yellow12: "#fffcee",

        red1: "#fed6d6",
        red2: "#fd9797",
        red3: "#fc5959",
        red4: "#fa0707",
        red5: "#b40404",
        red6: "#b40404",
        red7: "#870303",
        red8: "#5a0202",
        red9: "#3c0101",
        red10: "#FCBFBF",
        red11: "#FDD4D4",
        red12: "#feeaea",

        orange5: "#ff8b34",
        Pink6: "#FC4DA8",
      },
    },
  },
});
