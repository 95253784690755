<template>
  <v-dialog v-model="openDialog" persistent  width="550" class="rounded-lg">

    <v-form ref="form" v-model="valid">
      <v-card class="py-4 px-2 rounded-lg">
        <v-card-actions class="mb-5">
          <v-card-text class="text-capitalize pa-0">
            <span class="font-weight-bold text-h6">{{ $t('inquiry') }} </span>
            <span class="text-h6">{{ data?.name }}</span>
          </v-card-text>

          <v-btn small fab color="red5" text @click="close">
            <v-icon color="red5">mdi-close</v-icon>
          </v-btn>
        </v-card-actions>

        <v-card-text class="pa-2">
          {{ data?.description }}
        </v-card-text>

      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>

export default {
  name: "CustomerContactsDialog",

  data: () => ({
    dialogVisible: false, // Local property to manage dialog visibility
    valid: false,
    loading: false,
  }),
  computed: {
  },

  methods: {
    close() {
      this.closeDialog();
    },
  },
  watch: {
  },

  props: {
    openDialog: { default: false },
    closeDialog: { type: Function },
    data: { default: Object },
  },

}


</script>

<style lang="scss" scoped></style>
