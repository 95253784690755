<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="validateSaveBtn" :showClose="false" />
         <!-- End Of Header -->
         <v-form ref="form" v-model="valid" class="mt-3">

            <v-row>
               <v-col cols="12" md="12" lg="6">
                  <v-card class="shadow py-5 rounded-lg card_analysis">
                     <v-row class="align-center px-4 py-2 text-capitalize">
                        <v-col cols="12" class="text-capitalize">{{ $t('about us in arabic') }}</v-col>

                        <AnimatedLoading :isLoading="pageData.isLoading" class="mx-auto" />
                        <v-col cols="12" class="d-flex align-center justify-start" v-if="!pageData.isLoading">
                           <div class="d-flex align-center flex-wrap">
                              <GenericInput type="text" :value="form.address_ar" @input="form.address_ar = $event"
                                 label="the address" :required="true" :isLoading="pageData.editIsLoading"
                                 :cols="[12, 12, 12]">
                              </GenericInput>
                              <GenericInput type="texteditor" :value="form.description_ar"
                                 @input="form.description_ar = $event" label="description" :required="true"
                                 :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]">
                              </GenericInput>
                           </div>
                        </v-col>

                     </v-row>
                  </v-card>
               </v-col>
               <v-col cols="12" md="12" lg="6">
                  <v-card class="shadow py-5 rounded-lg card_analysis">
                     <v-row class="align-center px-4 py-2 text-capitalize">
                        <v-col cols="12" class="text-capitalize">{{ $t('about us in English') }}</v-col>

                        <AnimatedLoading :isLoading="pageData.isLoading" class="mx-auto" />
                        <v-col cols="12" class="d-flex align-center justify-start" v-if="!pageData.isLoading">
                           <div class="d-flex align-center flex-wrap">
                              <GenericInput type="text" :value="form.address_en" @input="form.address_en = $event"
                                 label="the address" :required="true" :isLoading="pageData.editIsLoading"
                                 :cols="[12, 12, 12]">
                              </GenericInput>
                              <GenericInput type="texteditor" :value="form.description_en"
                                 @input="form.description_en = $event" label="description" :required="true"
                                 :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]">
                              </GenericInput>
                           </div>
                        </v-col>

                     </v-row>
                  </v-card>
               </v-col>
               <v-col cols="12" md="12" lg="6">
                  <v-card class="shadow py-5 rounded-lg card_img" :key="counter">
                     <v-row class="align-center px-4 py-2 text-capitalize">

                        <v-col cols="12" class="text-capitalize">{{ $t("The image") }}</v-col>

                        <AnimatedLoading :isLoading="pageData.isLoading" class="mx-auto" />

                        <v-col cols="12" class="d-flex align-center justify-center mt-12" v-if="!pageData.isLoading">
                           <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                 <div v-bind="attrs" v-on="on">
                                    <PreviewImage :img="form.image" :callBackMethod="centerPhoto" :showDelete="false"
                                       :showText="false" :requestDeleteImage="requestDeleteImage" />
                                 </div>
                              </template>
                              <span>{{ '862' + 'x' + '689' }}</span>
                           </v-tooltip>
                        </v-col>

                     </v-row>
                  </v-card>
               </v-col>
            </v-row>
         </v-form>

      </v-container>
   </section>
</template>



<script>
import PreviewImage from '@/components/ui/PreviewImage.vue';


export default {
   name: "AboutUsEntity",

   data: () => ({
      pageData: {
         screen_code: "02-005",
         url: null,
         controlRoute: "website/about_us-control",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isTrashed: 1,
         rowsNumber: 15,
         tableHeader: [],
         search: '',
         rows: [],
         options: {},
         page: 1,
         pagination: {},
         rearrangementDialog: false,
      },
      valid: false,
      form: {
         image: null,
         address_ar: null,
         address_en: null,
         description_ar: null,
         description_en: null,
      },
      counter: 0,
   }),
   components: {
      PreviewImage
   },
   computed: {
      validateSaveBtn() {
         return this.valid && this.form.description_ar && this.form.description_en && this.form.image
      }
   },
   mounted() {
      this.pageMainData();
      this.getData();
   },
   methods: {
      centerPhoto(img) {
         this.form.image = img;
         if (
            this.$global.FILE_TYPE(img) !== 'png' &&
            this.$global.FILE_TYPE(img) !== 'jpg' &&
            this.$global.FILE_TYPE(img) !== 'svg' &&
            this.$global.FILE_TYPE(img) !== 'jpeg'
         ) {
            this.$store.state.snackbarTitle = this.$i18n.t("Please upload photos only")
            this.$store.state.snackbarType = "info";
            this.$store.state.showSnackbar = true;
            this.requestDeleteImage = true;
            this.form.image = '';
            img = null;
            this.counter++
         }
      },
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code)?.main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code)?.cat_title;
         this.pageData.entityName = this.$store.state.activeScreen?.sub_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code)?.url;
         this.pageData.options = {
            print: this.$global.CheckAction(this.pageData.screen_code, 5),
            delete: this.$global.CheckAction(this.pageData.screen_code, 4),
            edit: this.$global.CheckAction(this.pageData.screen_code, 3),
            restore: this.$global.CheckAction(this.pageData.screen_code, 4),
            view: this.$global.CheckAction(this.pageData.screen_code, 1),
            switch: false,
            selectRow: false,
            sortBy: 'id',
            tableHeader: false,
            searchInput: false,
            printButton: false,
            handleClickRow: false,
         }
      },
      getData() {
         this.pageData.isLoading = true;
         this.$api.GET_METHOD(`about_us`).then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
               this.form = response.data.items[0]
            }
         })
      },
      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            this.form._method = 'PUT'
            this.$api.POST_METHOD(`about_us/1`, this.form).then((response) => {
               this.pageData.isLoading = false;
               if (response.check) {
                  console.log('response');
               }
            })
         }
      },

   },
};
</script>

<style lang="scss" scoped>
.card_analysis,
.card_img {
   height: 100%;
}

.text_numbers {
   height: 60vh;
   overflow: auto;
}</style>

