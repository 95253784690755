<template>
  <v-dialog v-model="openDialog" @click:outside="close" :retain-focus="false" max-width="1300">
      <v-form ref="form" v-model="valid">
          <v-card class="shadow pa-5" :key="id">
              <div class="d-flex justify-space-between px-5">
                  <h5> {{ $t('Add date - day') | capitalize }} {{ data.date }}</h5>
                  <v-btn class="error--text" icon depressed @click="close">
                      <v-icon size="24" color="error"> mdi-close</v-icon>
                  </v-btn>
              </div>
              <section v-if="!isLoading && currentTime < '22:00:00'">
                  <v-row align="center">


                      <v-row class="pa-0 ma-0">
                          <section v-for="period in timePeriods" :key="period.label">
                              <!-- Only render if it's the morning period -->
                              <h2 v-if="currentDate == period.date ? period.label == 'Abbreviation' && currentTime < '11:00:00' : period.label === 'Abbreviation'"
                                  class="pa-4">
                                  {{ $t(period.label) }}
                              </h2>

                              <!-- Show Evening only if current time is before 22:00:00 -->
                              <h2 v-if="currentDate == period.date ? period.label == 'Evening' && currentTime < '22:00:00' : period.label === 'Evening'"
                                  class="pa-4">
                                  {{ $t(period.label) }}
                              </h2>
                              <v-col cols="12" class="d-flex flex-wrap">
                                  <div v-for="time in filterTimeSlots(timeSlots, period.condition)" :key="time.id"
                                      class="d-flex align-center justify-center pa-0 ma-0">
                                      <v-btn text depressed class="mx-1 my-2"
                                          v-if="currentDate == period.date ? time.time > currentTime : true"
                                          :class="{ 'selectedTime': form.times.includes(time.time), 'unSelectedTime': !form.times.includes(time.time) }"
                                          @click="data.date >= currentDate ? selectedTime(time) : false">
                                          {{ time.time | time }}
                                      </v-btn>
                                  </div>
                              </v-col>
                          </section>


                      </v-row>

                      <!-- Cancel button -->
                      <v-col cols="6" class="d-flex justify-center px-16" v-if="data.date >= currentDate">
                          <v-btn depressed text @click="CancelAppointment" height="45" block
                              class="rounded-lg font-weight-bold white--text" color="error" :loading="saveDataLoading">
                              {{ $t('Cancel the appointment') }}
                          </v-btn>
                      </v-col>
                      <v-col cols="6" class="d-flex justify-center px-16" v-if="data.date >= currentDate">
                          <v-btn depressed block :disabled="false" @click="save" height="45"
                              class="rounded-lg font-weight-bold blue6 white--text" :loading="saveDataLoading">
                              {{ $t('save') }}
                          </v-btn>
                      </v-col>

                  </v-row>
              </section>

              <section v-if="currentTime > '22:00:00' && !isLoading">
                  <div class="my-3">
                      <img src="@/assets/img/svg_icons/no_data.svg" height="150" class="d-block my-3 mx-auto"
                          alt="booking">
                      <div class="text-h6 text-center  gray8--text">
                          {{ $t('There are no working hours to book at the present time') | capitalize }}
                      </div>
                  </div>
              </section>
              <AnimatedLoading class="mt-10  pa-5" :isLoading="isLoading" height="70" :text="false" :hideText="false" />
          </v-card>
      </v-form>

  </v-dialog>
</template>
<script>


export default {

  name: "ReservationDialog",

  data() {
      return {
          valid: null,
          id: null,
          isLoading: false,
          saveDataLoading: false,
          dialogTitle: '',
          timeSlots: [],
          dayAvailableTimes: [],
          form: {
              times: []
          }

      };
  },


  mounted() {
      this.timeSlots = [
          {
              id: 1,
              time: '07:00:00',
          },
          {
              id: 2,
              time: '08:00:00',
          },
          {
              id: 3,
              time: '09:00:00',
          },
          {
              id: 4,
              time: '10:00:00',
          },
          {
              id: 5,
              time: '11:00:00',
          },
          {
              id: 6,
              time: '12:00:00',
          },
          {
              id: 7,
              time: '13:00:00',
          },
          {
              id: 8,
              time: '14:00:00',
          },
          {
              id: 9,
              time: '15:00:00',
          },
          {
              id: 10,
              time: '16:00:00',
          },
          {
              id: 11,
              time: '17:00:00',
          },
          {
              id: 12,
              time: '18:00:00',
          },

          {
              id: 13,
              time: '19:00:00',
          },
          {
              id: 14,
              time: '20:00:00',
          },
          {
              id: 15,
              time: '21:00:00',
          },
          {
              id: 16,
              time: '22:00:00',
          },
      ]
  },

  computed: {

      timePeriods() {
          return [
              { label: 'Abbreviation', condition: time => time.time <= '11:00:00', date: this.data.date },
              { label: 'Evening', condition: time => time.time >= '12:00:00', date: this.data.date },
          ];
      },
      disabled() {
          return (
              Object.keys(this.form).some(key => !this.form[key])
          );
      },

      currentTime() {
          const now = new Date();
          const formattedTime = `${now.getHours().toString().padStart(2, '0')}:${now.getMinutes().toString().padStart(2, '0')}:${now.getSeconds().toString().padStart(2, '0')}`;
          return formattedTime;
      },

      currentDate() {
          const now = new Date();
          const formattedDate = `${now.getFullYear()}-${(now.getMonth() + 1)
              .toString()
              .padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}`;
          return formattedDate;
      },

  },

  methods: {

      filterTimeSlots(timeSlots, condition) {
          return timeSlots.filter(time => condition(time));
      },

      getData() {
          this.isLoading = true
          this.$api.GET_METHOD(`available_time?day=${this.data.date}`).then((response) => {
              this.isLoading = false
              if (response.check) {
                  this.dayAvailableTimes = response.data.items
                  const specificDate = this.data.date;
                  if (this.dayAvailableTimes[specificDate]) {
                      this.dayAvailableTimes[specificDate].forEach(time => {

                          if (!this.form.times.includes(time.time)) {
                              this.form.times.push(time.time);
                          }
                      });
                  }
              }
          });

      },

      save() {
          if (Object.keys(this.dayAvailableTimes).length !== 0) {
              this.saveDataLoading = true;
              delete this.form.day
              this.form._method = "PUT"
              this.$api.POST_METHOD(`available_time/${this.data.date}`, this.form).then((response) => {
                  this.saveDataLoading = false;
                  if (response.check) {
                      this.updateBackGround(false)
                      this.close()
                  }
              })

          } else {
              this.saveDataLoading = true;
              this.form.day = this.data.date
              this.$api.POST_METHOD(`available_time`, this.form).then((response) => {
                  this.saveDataLoading = false;
                  if (response.check) {
                      this.updateBackGround(false)
                      this.close()
                  }
              })
          }
      },

      CancelAppointment() {
          const form = {
              times: [],
              _method: "PUT"
          }
          this.saveDataLoading = true;
          this.$api.POST_METHOD(`available_time/${this.data.date}`, form).then((response) => {
              this.saveDataLoading = false;
              if (response.check) {
                  this.updateBackGround(false)
                  this.close()
              }
          })
      },

      close() {
          this.form = {
              times: []
          }
          this.id = 0
          this.dayAvailableTimes = []
          this.closeDialog()

      },

      selectedTime(time) {
          this.form.day = this.data.date
          const index = this.form.times.indexOf(time.time);
          if (index === -1) {
              this.form.times.push(time.time);
          } else {
              this.form.times.splice(index, 1);
          }
      },

  },

  watch: {
      openDialog() {
          if (this.openDialog) {
              this.getData()
              this.id = +1
          }
      },

  },


  props: {
      openDialog: { default: false },
      closeDialog: { type: Function },
      updateBackGround: { type: Function },
      data: { default: Object },
  },

  components: {}
}
</script>

<style lang="scss" scoped>
.scrollBar {
  overflow: auto;
  white-space: nowrap;
  max-height: 69.5vh;
  background-color: #fff;
}

.selectedTime {
  border-radius: 0.3125rem;
  background: var(--Primery, #00294B);
  color: var(--White, #FFF);
  width: 7rem !important;
  padding: 0.375rem 0.625rem;
  user-select: none !important;
  -webkit-user-select: none;
}

.unSelectedTime {
  background: var(--SconderySelcted, rgba(51, 204, 255, 0.10));
  border-radius: 0.3125rem;
  color: var(--Primery, #00294B);
  width: 7rem !important;
  padding: 0.375rem 0.625rem;
  user-select: none !important;
  -webkit-user-select: none;
}
</style>