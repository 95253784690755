<template>
   <section>
      <v-container fluid>
         <!-- Header -->
      <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid && validateSlidersImgs" :showClose="false" />
         <!-- End Of Header -->

         <v-form ref="form" v-model="valid" class="mt-3" v-if="!pageData.isLoading">
            
            <v-card class="rounded" :key="counter">
               <v-toolbar flat color="blue8" dark>
                  <v-toolbar-title class="pa-2 blue1--text">{{ $t('images') }}</v-toolbar-title>
               </v-toolbar>
               <v-tabs vertical v-model="tab">

                  <draggable :move="checkMove" @start="dragging = true" @end="dragging = false" :list="form.slides">

                     <v-tab v-for="(tab, i) in form.slides" :key="i">
                        <v-icon left>
                           mdi-view-carousel
                        </v-icon>

                        {{ $t('image') }} {{ tab?.id || (i + 1) }}
                     </v-tab>
                  </draggable>

                  <v-tab-item v-for="(tab, i) in form.slides" :key="i">
                     <v-card flat class="pa-3">
                        <v-card-text class="d-flex align-center">
                           <v-row class="flex-column">

                              <v-col cols="12" sm="12" v-if="!pageData.isLoading">
                                 <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                       <div v-bind="attrs" v-on="on">
                                          <PreviewImage :img="tab.image"
                                             :callBackMethod="(img) => callBackSlideImg(i, img)" :showDelete="false"
                                             :showText="false" :requestDeleteImage="requestDeleteImage" />
                                       </div>
                                    </template>
                                    <span>{{ '1080' + 'x' + '1024' }}</span>
                                 </v-tooltip>
                              </v-col>


                              <v-col cols="12" sm="12">
                                 <DynamicTable :isLoading="pageData.isLoading" :data="[tab]" :header="pageData.tableHeader"
                                    :option="pageData.options" :pageData="pageData">
                                    <template v-slot:td="{ row, header }">
                                       <div class="flex-grow-1" v-if="header.key == 'name_ar'">
                                          <GenericInput type="text"  @input="row.header_ar = $event"
                                             :value="row.header_ar" :required="row.header_active == 1"
                                             :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]">
                                          </GenericInput>

                                          <GenericInput type="text" @input="row.text_ar = $event"
                                             :value="row.text_ar" :required="row.text_active == 1"
                                             :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]">
                                          </GenericInput>

                                          <GenericInput type="text" @input="row.btn_ar = $event"
                                             :value="row.btn_ar" :required="row.btn_active == 1"
                                             :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]">
                                          </GenericInput>

                                       </div>
                                       <div class="flex-grow-1" v-if="header.key == 'name_en'">
                                          <GenericInput type="text" @input="row.header_en = $event"
                                             :value="row.header_en" :required="row.header_active == 1"
                                             :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]">
                                          </GenericInput>

                                          <GenericInput type="text" @input="row.text_en = $event"
                                             :value="row.text_en" :required="row.text_active == 1"
                                             :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]">
                                          </GenericInput>

                                          <GenericInput type="text" @input="row.btn_en = $event"
                                             :value="row.btn_en" :required="row.btn_active == 1"
                                             :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]">
                                          </GenericInput>
                                       </div>
                                       <div v-if="header.key == 'active'">
                                          <GenericInput type="switch" class="py-1" :value="row.header_active"
                                             @input="row.header_active = $event" :required="false"
                                             :isLoading="pageData.editIsLoading" :cols="[3, 3, 3]">
                                          </GenericInput>
                                          <GenericInput type="switch" class="py-1" :value="row.text_active"
                                             @input="row.text_active = $event" :required="false"
                                             :isLoading="pageData.editIsLoading" :cols="[3, 3, 3]">
                                          </GenericInput>
                                          <GenericInput type="switch" class="py-1" :value="row.btn_active"
                                             @input="row.btn_active = $event" :required="false"
                                             :isLoading="pageData.editIsLoading" :cols="[3, 3, 3]">
                                          </GenericInput>
                                       </div>
                                    </template>
                                 </DynamicTable>
                              </v-col>
                           </v-row>

                        </v-card-text>
                     </v-card>
                  </v-tab-item>

               </v-tabs>
            </v-card>
         </v-form>
         <AnimatedLoading :isLoading="pageData.isLoading" class="mt-16" />
      </v-container>
   </section>
</template>



<script>
import PreviewImage from '@/components/ui/PreviewImage.vue';
import draggable from 'vuedraggable';

export default {
   name: "SlidersEntity",

   data: () => ({
      pageData: {
         screen_code: "02-008",
         url: null,
         controlRoute: "website/sliders-control",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isTrashed: 1,
         rowsNumber: 15,
         tableHeader: [],
         search: '',
         rows: [],
         options: {},
         page: 1,
         pagination: {},
         rearrangementDialog: false,
      },
      tab: null,
      valid: false,
      logo_image: null,
      language_icon: null,
      form: {
         slides: []
      },
      requestDeleteImage: false,
      counter: 0,
      myArray: [],
      services: [],
      dragging: false
   }),
   components: {
      PreviewImage,
      draggable
   },
   computed: {
      validateSlidersImgs() {
         return this.form.slides.every((slider) => slider.image !== null)
      }
   },
   watch: {
   },
   mounted() {
      this.pageMainData();
      this.getData();
   },
   methods: {
      checkMove: function (e) {
         window.console.log("Future index: " + e.draggedContext.futureIndex);
         this.tab = e.draggedContext.futureIndex
      },
      callBackSlideImg(i, img) {
         this.form.slides[i].image = img
         if (
            this.$global.FILE_TYPE(img) !== 'png' &&
            this.$global.FILE_TYPE(img) !== 'jpg' &&
            this.$global.FILE_TYPE(img) !== 'svg' &&
            this.$global.FILE_TYPE(img) !== 'jpeg'
         ) {
            this.$store.state.snackbarTitle = this.$i18n.t("Please upload photos only")
            this.$store.state.snackbarType = "info";
            this.$store.state.showSnackbar = true;
            this.requestDeleteImage = true;
            this.form.slides[i].image = '';
            img = null;
            this.counter++
         }
      },
      pageMainData() {
         console.log( this.$global.FilterPermissions(this.pageData.screen_code));
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code)?.main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code)?.cat_title;
         this.pageData.entityName = this.$store.state.activeScreen?.sub_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code)?.url;
         this.pageData.tableHeader = [
            // { text: "serial", key: "id", type: 'text', classes: "" },
            { text: "name_ar", key: "name_ar", type: 'slot', classes: "" },
            { text: "name_en", key: "name_en", type: 'slot', classes: "" },
            { text: "status", key: "active", type: 'slot', classes: "" },
         ]
         this.pageData.options = {
            print: this.$global.CheckAction(this.pageData.screen_code, 5),
            delete: this.$global.CheckAction(this.pageData.screen_code, 4),
            edit: this.$global.CheckAction(this.pageData.screen_code, 3),
            restore: this.$global.CheckAction(this.pageData.screen_code, 4),
            view: this.$global.CheckAction(this.pageData.screen_code, 1),
            switch: false,
            selectRow: false,
            sortBy: 'id',
            tableHeader: false,
            searchInput: false,
            printButton: false,
            handleClickRow: false,
         }
      },
      getData() {
         if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
            this.pageData.isLoading = true;
            this.$api.GET_METHOD(`slide`).then((response) => {
               this.pageData.isLoading = false;
               if (response.check) {
                  this.form.slides = response.data.items;
                  console.log("this.form",this.form);
               }
            })
         }
         else {
            this.$router.push('/')
         }
      },
      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            // this.form._method = 'PUT'
            this.$api.POST_METHOD(`slide_update`, this.form).then((response) => {
               this.pageData.isLoading = false;
               if (response.check) {
                  console.log('response');
               }
            })
         }
      },
   },
};
</script>


